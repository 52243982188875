import React, { Component } from 'react'
import Input from '../../common/forms/Input'

export default class UserLoginForm extends Component {
  render() {
    const { user, error, onChange, onSubmit, onCancel } = this.props

    return (
      <form className='login-form' onSubmit={onSubmit}>
        <div>{error}</div>
        <Input
          type='email'
          name='email'
          placeholder='E-mail'
          value={user.email}
          onChange={onChange}
          required />
        <Input
          type='password'
          name='password'
          placeholder='Password'
          value={user.password}
          onChange={onChange}
          required />
        <div className='login-buttons'>
          <span className='cancel-button' onClick={onCancel} >Cancel</span>
          <button className='btn btn-login'>Login</button>
        </div>
      </form >
    )
  }
}
