import React, { Component } from 'react'
import ListEduDiplomas from './ListEduDiplomas'
import { verifiedPage } from '../common/helpers/pageText.js'
import coverImage from '../../images/register/gdir_cover_profile.png'
import ProfileComponent from '../Institutions/ProfileComponent'
import RemoteInstitution from '../../api/remote_institution'

export default class ListEduVerifiedDiplomas extends Component {
  constructor (props) {
    super(props)

    this.state = {
      page: 1,
      items: [],
      limit: 5,
      user: {
        email: '',
        username: '',
        institution: ''
      },
      diploma: {
        verified: []
      }
    }

    this.goToPrevPage = this.goToPrevPage.bind(this)
    this.goToNextPage = this.goToNextPage.bind(this)
  }

  goToPrevPage () {
    const { verified } = this.state.diploma
    let page = this.state.page

    if (page === 1) {
      return
    }
    page -= 1

    this.setState({
      page,
      items: this.updateItems(verified, page)
    })
    this.props.history.push(`/edu/verified?page=${page}`)
  }

  goToNextPage () {
    const { verified } = this.state.diploma
    let page = this.state.page

    if (this.state.items.length === 0) {
      return
    }
    page += 1

    this.setState({
      page,
      items: this.updateItems(verified, page)
    })
    this.props.history.push(`/edu/verified?page=${page}`)
  }

  updateItems (data, page) {
    const { limit } = this.state
    let filteredData = data.slice(
      (page - 1) * limit,
      (page - 1) * limit + limit
    )

    return filteredData
  }

  componentDidMount () {
    this.getData()
  }

  async getData () {
    const email = window.localStorage.getItem('gdirUser')
    const username = window.localStorage.getItem('gdirUsername')
    const institution = window.localStorage.getItem('gdirInstitutionName')
    const { page, limit } = this.state

    const diplomaData = await RemoteInstitution.getVerifiedDiplomas()
    let filteredData = ''
    let verified = ''

    if (diplomaData.success) {
      filteredData = diplomaData.data.slice(
        (page - 1) * limit,
        (page - 1) * limit + limit
      )

      verified = diplomaData.data
    }

    this.setState({
      items: filteredData,
      user: {
        email,
        username,
        institution
      },
      diploma: {
        verified
      }
    })
  }

  render () {
    const { username, institution } = this.state.user
    let { page, items, limit } = this.state

    return (
      <div className='edu-verified-page'>
        <div className='edu-verified-page-cover'>
          <img
            className='edu-verified-page-img'
            src={coverImage}
            alt='Edu verified img'
          />
          <span className='title'>{verifiedPage.title}</span>
          <div className='cover-bottom-line' />
        </div>
        <div className='content-wrapper'>
          <ProfileComponent user={username} edu={institution} />
          <div className='diploma-wrapper'>
            <div id='verified-diploma'>Verified diplomas</div>
            <ListEduDiplomas diploma={items} />
            <div id='paginator'>
              {page > 1 && (
                <button className='btn btn-prev' onClick={this.goToPrevPage} />
              )}
              <span>{page}</span>
              {items.length >= limit && (
                <button className='btn btn-next' onClick={this.goToNextPage} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
