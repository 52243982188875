import Remote from './remote'

const baseUrl = '/user'

export default class RemoteUser {
  static getAll () {
    return Remote.get(`${baseUrl}/all`)
  }

  static getUserData () {
    return Remote.get(`${baseUrl}/edit`)
  }

  static editUser (user) {
    return Remote.update(`${baseUrl}/edit`, user)
  }

  static addDiploma (diploma) {
    return Remote.post(`${baseUrl}/newdiploma`, diploma)
  }

  static getVerifiedDiplomas () {
    return Remote.get(`${baseUrl}/verified`)
  }

  static getPendingDiplomas () {
    return Remote.get(`${baseUrl}/pending`)
  }

  static getRejectedDiplomas () {
    return Remote.get(`${baseUrl}/rejected`)
  }

  static getDiplomas (status) {
    return Remote.post(`${baseUrl}/diplomas`, status)
  }
}
