export let redirectionLink = { path: '' }

export function getId (data) {
  let current = data.length
  return ++current
}

export function getDateNow (dateTimeString = null) {
  let today = new Date(dateTimeString)
  let dd = today.getDate()
  let mm = today.getMonth() + 1

  var yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  today = yyyy + '-' + mm + '-' + dd
  return today
}

export function getDateOnly (dateTime) {
  return getDateNow(dateTime)
}

export function getTimeOnly (dateTime) {
  const date = new Date(dateTime)
  let hh = date.getHours()
  let mm = date.getMinutes()
  let ss = date.getSeconds()

  if (hh < 10) {
    hh = '0' + hh
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  if (ss < 10) {
    ss = '0' + ss
  }

  const fullTime = hh + ':' + mm + ':' + ss
  return fullTime
}

function numberPrefixGenerator (number) {
  const maxNumber = 100000
  const maxCounter = maxNumber.toString().length
  const currentCounter = number.toString().length
  const diff = maxCounter - currentCounter

  const prefix = '0'.repeat(diff)

  return prefix + number
}

export function generateGdirNumber (uic, gdirCount) {
  return uic + numberPrefixGenerator(gdirCount)
}

export function updateLocalStorage (userEmail, username) {
  window.localStorage.setItem('gdirUser', userEmail)
  window.localStorage.setItem('gdirUsername', username)
}
