import React, { Component } from 'react'
import SearchBox from '../Search/SearchBox'
import userImage from '../../images/home/user.png'
import institutionImage from '../../images/home/institution.png'
import logo from '../../images/logo/logo-1.png'

export default class HomePage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      search: '',
      error: ''
    }

    this.onUserClick = this.onUserClick.bind(this)
    this.onInstitutionClick = this.onInstitutionClick.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  componentDidMount () {
    const user = window.localStorage.getItem('gdirUser')
    let role = window.localStorage.getItem('gdirUserRole')

    if (user && role) {
      if (role === 'institution') {
        role = 'edu'
      }

      const homePath = user && role ? `/${role}/profile` : '/'

      this.props.history.push(homePath)
    }
  }

  onUserClick () {
    return this.props.history.push('/auth/user/register')
  }

  onInstitutionClick () {
    return this.props.history.push('/auth/edu/register')
  }

  onChangeHandler (e) {
    this.setState({
      [e.target.name]: e.target.value.toUpperCase().trim()
    })
  }

  onSubmitHandler (e) {
    e.preventDefault()
    const searchValue = e.target.search.value

    if (searchValue) {
      return this.props.history.push('/search?' + searchValue)
    }
    this.setState({
      error: 'Please enter GDIR number in search field!'
    })
  }

  render () {
    const { search } = this.state
    const isVisible = this.state.error || this.state.error !== ''
    return (
      <section className='home-container'>
        <div className='search-box-info'>
          <img
            className='logo-search'
            src={logo}
            alt='GDIR search diploma logo'
          />
          <p className='search-desc'>Global Diploma Identification Register</p>
        </div>
        <SearchBox
          value={search}
          error={this.state.error}
          isVisible={isVisible}
          onChange={this.onChangeHandler}
          onSubmit={this.onSubmitHandler}
        />
        <div className='registration-view'>
          <div className='gdir-info'>
            <p>GDIR Information</p>
          </div>
          <div className='registration-section'>
            <div className='registration-card'>
              <img
                className='home-page-register-img'
                src={institutionImage}
                alt='institution img'
              />
              <p className='reg-desc'>
                Educational institutions registration in Global Diploma
                Identification Register
              </p>
              <button className='btn btn-reg' onClick={this.onInstitutionClick}>
                Institution registration
              </button>
            </div>
            <div className='registration-card'>
              <img
                className='home-page-register-img'
                src={userImage}
                alt='user img'
              />
              <p className='reg-desc'>
                Users registration in Global Diploma Identification Register
              </p>
              <button className='btn btn-reg' onClick={this.onUserClick}>
                User registration
              </button>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
