import React, { Component } from 'react'

import Header from './components/common/Header'
import Footer from './components/common/Footer'
import Routes from './components/common/routes/Routes'

export default class App extends Component {
  render () {
    return (
      <div id='site-wrapper'>
        <Header />
        <main id='contents-wrapper'>
          <Routes />
        </main>
        <Footer />
      </div>
    )
  }
}
