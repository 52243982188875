import React, { Component } from 'react'
import FaqBox from './FaqBox'
import { questions } from '../common/helpers/questionData'
import { faq } from '../common/helpers/pageText'
import coverImage from '../../images/register/gdir_cover_profile.png'

export default class FaqPage extends Component {
  render() {
    return (
      <div className='faq-page'>
        <div className='faq-page-cover'>
          <img className='faq-page-img' src={coverImage} alt='FAQ img' />
          <span className='title'>{faq.title}</span>
          <div className='cover-bottom-line'>
          </div>
        </div>
        <div className='page-content'>
          {questions.map(q => {
            return <FaqBox key={q.id} data={q} />
          })}
        </div>
      </div>
    )
  }
}
