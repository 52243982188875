import React, { Component } from 'react'
import CreateDiplomaEduForm from './CreateDiplomaEduForm'
import ProfileComponent from '../Institutions/ProfileComponent'
import { diplomaPage } from '../common/helpers/pageText'
import coverImage from '../../images/register/gdir_cover_profile.png'
import RemoteInstitution from '../../api/remote_institution'

export default class CreateDiplomaEdu extends Component {
  constructor (props) {
    super(props)

    this.state = {
      owner: '',
      degree: '',
      grade: '',
      fieldOfStudy: '',
      professionalQualification: '',
      registrationNumber: '',
      image: '',
      issueDate: '',
      error: ''
    }

    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  onChangeHandler (e) {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      [e.target.name]: value
    })
  }

  async onSubmitHandler (e) {
    e.preventDefault()

    const {
      owner,
      degree,
      grade,
      fieldOfStudy,
      professionalQualification,
      registrationNumber,
      image,
      issueDate
    } = this.state

    const newDiploma = {
      owner,
      degree,
      grade,
      field_of_study: fieldOfStudy,
      professional_qualification: professionalQualification,
      registration_number: registrationNumber,
      diploma_image_path: image,
      issue_date: issueDate
    }

    const addDiploma = await RemoteInstitution.addDiploma(newDiploma)

    if (addDiploma.success) {
      return this.props.history.push('/edu/profile')
    } else {
      this.setState({
        error: 'Invalid data added'
      })
    }
  }

  render () {
    const {
      owner,
      degree,
      grade,
      fieldOfStudy,
      professionalQualification,
      registrationNumber,
      image,
      issueDate,
      error
    } = this.state
    const diploma = {
      owner,
      degree,
      grade,
      fieldOfStudy,
      professionalQualification,
      registrationNumber,
      image,
      issueDate
    }
    const username = window.localStorage.getItem('gdirUsername')
    const institution = window.localStorage.getItem('gdirInstitutionName')

    return (
      <div className='diploma-create-page'>
        <div className='diploma-create-page-cover'>
          <img
            className='diploma-create-page-img'
            src={coverImage}
            alt='Diploma page img'
          />
          <span className='title'>{diplomaPage.title}</span>
          <div className='cover-bottom-line' />
        </div>
        <div className='content-wrapper'>
          <ProfileComponent user={username} edu={institution} />
          <div className='secondary-wrapper'>
            <CreateDiplomaEduForm
              diploma={diploma}
              error={error}
              onChange={this.onChangeHandler}
              onSubmit={this.onSubmitHandler}
            />
          </div>
        </div>
      </div>
    )
  }
}
