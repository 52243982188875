import React, { Component } from 'react'
import Select from './forms/Select'

export default class LanguageSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'BG'
    }

    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  onChangeHandler(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    const { language } = this.state
    const languageTypes = ['EN', 'BG']
    // const languages = languageTypes.map(l => {
    //   return <li>{l}</li>
    // }

    return (
      // <ul className='dropdown'>
      //   {languages}
      // </ul>
      < Select
        name='language'
        value={language}
        options={languageTypes}
        onChange={this.onChangeHandler} />
    )
  }
}
