import React, { Component } from 'react'
import UserRegisterForm from './RegisterForm'
import RemoteAuth from '../../../api/remote_auth'
import registrationCoverImage from '../../../images/register/gdir_cover_profile.png'

export default class UserRegisterPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      error: ''
    }

    this.onCancel = this.onCancel.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  onCancel () {
    return this.props.history.goBack()
  }

  onChangeHandler (e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      [e.target.name]: value
    })
  }

  async onSubmitHandler (e) {
    e.preventDefault()
    const {
      firstName,
      lastName,
      email,
      password
    } = this.state
    const newUser = {
      first_name: firstName,
      last_name: lastName,
      email,
      password
    }
    const res = await RemoteAuth.registerUser(newUser)

    if (!res.success) {
      return this.setState({ error: res.message })
    }

    return this.props.history.push('/auth/user/login')
  }

  render () {
    const { firstName, lastName, email, password, confirmPassword, error } = this.state
    const user = {
      firstName,
      lastName,
      email,
      password,
      confirmPassword
    }

    return (
      <div className='registration-page'>
        <div className='registration-page-cover'>
          <img className='registration-page-img' src={registrationCoverImage} alt='Registration img' />
          <span className='title'>user registration</span>
          <div className='cover-bottom-line' />
        </div>
        <UserRegisterForm
          user={user}
          error={error}
          onChange={this.onChangeHandler}
          onSubmit={this.onSubmitHandler}
          onCancel={this.onCancel} />
      </div>
    )
  }
}
