const baseUrl = 'http://gdir.griffinhearts.com'
const printerUrl = 'http://192.168.11.11:8080'
// const baseUrl = 'http://localhost:80'

const getOptions = () => ({
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const handleJsonResponse = res => res.json()

const getToken = () => window.localStorage.getItem('token')

const applyAuthorizationHeader = (options, authenticated) => {
  if (authenticated) {
    options.headers.Authorization = `Bearer ${getToken()}`
  }
}

class Remote {
  static get (url, authenticated = true) {
    let options = getOptions()
    options.method = 'GET'

    applyAuthorizationHeader(options, authenticated)

    return window.fetch(`${baseUrl}${url}`, options).then(handleJsonResponse)
  }

  static post (url, data, authenticated = true) {
    let options = getOptions()
    options.method = 'POST'
    options.body = JSON.stringify(data)
    applyAuthorizationHeader(options, authenticated)

    return window.fetch(`${baseUrl}${url}`, options).then(handleJsonResponse)
  }

  static update (url, data, authenticated = true) {
    let options = getOptions()
    options.method = 'PUT'
    options.body = JSON.stringify(data)
    applyAuthorizationHeader(options, authenticated)

    return window.fetch(`${baseUrl}${url}`, options).then(handleJsonResponse)
  }

  static printerGet (url) {
    let options = getOptions()
    options.method = 'GET'

    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve({ tag: 'd' })
    //   }, 1300)
    // })

    return window
      .fetch(`${printerUrl}${url}`, options)
      .then(handleJsonResponse)
      .catch(e => {
        return {
          error: e.toString(),
          printerUrl
        }
      })
  }

  static printerPost (url, data) {
    let options = getOptions()
    options.method = 'POST'
    options.body = JSON.stringify(data)

    // console.log(data)

    return window
      .fetch(`${printerUrl}${url}`, options)
      .then(handleJsonResponse)
      .catch(e => {
        return {
          error: e.toString(),
          printerUrl
        }
      })
  }
}

export default Remote
