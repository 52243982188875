export let diploma = [
  {
    id: 1,
    owner: 'Ivan Petrov',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQji8CrHy-Mf6Zo-SIAyCNwShGNdLL7aUeLC_5ge6wifDxCs97d',
    institution: 'Sofia University St. Kliment Ohridski',
    fieldOfStudy: 'Marketing',
    gdir: 'BULSUKO000001',
    verificationDate: '2017-12-10',
    verificationStatus: 'verified',
    verificationRequest: false,
    issueDate: '2015-08-11',
    registrationNumber: 'AA-BB-A',
    professionalQualification: 'Marketing specialist',
    degree: 'Bachelor',
    user: 'test@test.bg'
  },
  {
    id: 2,
    owner: 'Ivan Petrov',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQji8CrHy-Mf6Zo-SIAyCNwShGNdLL7aUeLC_5ge6wifDxCs97d',
    institution: 'Sofia University St. Kliment Ohridski',
    fieldOfStudy: 'Finance',
    gdir: '',
    verificationDate: '',
    verificationStatus: 'pending',
    verificationRequest: true,
    issueDate: '2001-01-05',
    registrationNumber: 'CC-AA-C',
    professionalQualification: 'Finance expert',
    degree: 'Master',
    user: 'test@test.bg'
  },
  {
    id: 3,
    owner: 'Ivan Petrov',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQji8CrHy-Mf6Zo-SIAyCNwShGNdLL7aUeLC_5ge6wifDxCs97d',
    institution: 'Sofia University St. Kliment Ohridski',
    fieldOfStudy: 'IT',
    gdir: '',
    verificationDate: '',
    verificationStatus: 'pending',
    verificationRequest: true,
    issueDate: '2001-01-05',
    registrationNumber: 'CC-AA-C',
    professionalQualification: 'Finance expert',
    degree: 'Master',
    user: 'test@test.bg'
  },
  {
    id: 4,
    owner: 'Petar Mitev',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQji8CrHy-Mf6Zo-SIAyCNwShGNdLL7aUeLC_5ge6wifDxCs97d',
    institution: 'Sofia University St. Kliment Ohridski',
    fieldOfStudy: 'Invalid',
    gdir: '',
    verificationDate: '',
    verificationStatus: 'pending',
    issueDate: '2018-10-18',
    registrationNumber: 'AA-AA-A',
    professionalQualification: 'IT',
    degree: 'Master',
    user: 'test@test.bg'
  },
  {
    id: 5,
    owner: 'Superman',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQji8CrHy-Mf6Zo-SIAyCNwShGNdLL7aUeLC_5ge6wifDxCs97d',
    institution: 'ofia University St. Kliment Ohridski',
    fieldOfStudy: 'mkaatg',
    verificationDate: '',
    verificationStatus: 'pending',
    issueDate: '2018-11-29',
    registrationNumber: 'BB-BB-A',
    professionalQualification: 'IT',
    degree: 'Bachelor',
  // user: 'test@test.bg'
  },
  {
    id: 6,
    owner: 'Kolio Kolev',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQji8CrHy-Mf6Zo-SIAyCNwShGNdLL7aUeLC_5ge6wifDxCs97d',
    institution: 'ofia University St. Kliment Ohridski',
    fieldOfStudy: 'mktg',
    gdir: '',
    verificationDate: '',
    verificationStatus: 'pending',
    issueDate: '2015-03-10',
    registrationNumber: 'CC-AA-A',
    professionalQualification: 'IT',
    degree: 'Master',
  // user: 'test@test.bg'
  },
  {
    id: 7,
    owner: 'Ivan Iliev',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQji8CrHy-Mf6Zo-SIAyCNwShGNdLL7aUeLC_5ge6wifDxCs97d',
    institution: 'asd',
    fieldOfStudy: 'mktg',
    gdir: '012121',
    verificationDate: '2011-08-13',
    verificationStatus: 'verified',
    issueDate: '2017-10-22',
    registrationNumber: 'AB-AC-A',
    professionalQualification: 'IT',
    degree: 'Bachelor'
  }
]
