import React, { Component } from 'react'
import { printPage } from '../common/helpers/pageText.js'
import coverImage from '../../images/register/gdir_cover_profile.png'
import UserProfileComponent from '../Users/ProfileComponent'
import EduProfileComponent from '../Institutions/ProfileComponent'
// import qr from 'qrcode'
import QRCode from 'qrcode.react'
import html2canvas from 'html2canvas'
import SendToPrinter from './SendToPrinter'
import ReactToPrint from 'react-to-print'
import RemotePrinter from '../../api/remote_printer'
import RemoteDiploma from '../../api/remote_diploma'

export default class PrintPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      user: {
        email: '',
        name: '',
        role: '',
        institution: ''
      },
      canvasWidth: 600,
      canvasHeight: 250,
      qrSize: 200,
      gdir: '',
      imageData: '',
      rfid: '',
      rfidMessage: '',
      appendingMessage: '',
      errorMsg: '',
      successMsg: '',
      printMsg: '',
      loading: true,
      print: false,
      printing: false,
      stickerPrint: false,
      printReady: false,
      appending: false,
      error: false
    }

    this.handlePrint = this.handlePrint.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleUndo = this.handleUndo.bind(this)
    this.handleLoad = this.handleLoad.bind(this)
    this.handleStickerPrint = this.handleStickerPrint.bind(this)
    this.handlePrinting = this.handlePrinting.bind(this)
  }

  componentDidMount () {
    this.getData()
  }

  async getData () {
    const user = window.localStorage.getItem('gdirUser')
    const username = window.localStorage.getItem('gdirUsername')
    const userRole = window.localStorage.getItem('gdirUserRole')
    const institution = window.localStorage.getItem('gdirInstitutionName')
    const gdir = this.props.location.search.slice(1)

    this.setState({
      user: {
        email: user,
        name: username,
        role: userRole,
        institution
      },
      gdir
    })
  }

  async generatePng () {
    const element = await document.getElementById('qr-wrapper')
    const { canvasWidth, canvasHeight } = this.state
    return html2canvas(element, { width: canvasWidth, height: canvasHeight })
    // .then(canvas => {
    //   const imgData = canvas.toDataURL('image/png')
    // const imgDataSplit = imgData.split(',')
    // const base64Image = imgDataSplit[1]
    // console.log(imgData)
    // })
  }

  async handleLoad () {
    const imgData = await this.generatePng()
    // console.log(imgData.toDataURL())
    const data = await imgData.toDataURL()

    this.setState({
      imageData: data,
      print: true
    })
  }

  handleCancel () {
    return this.props.history.goBack()
  }

  handlePrint () {
    document.getElementById('hiddenBtn').click()
  }

  handleUndo () {
    this.setState({
      print: false,
      stickerPrint: false,
      imageData: '',
      rfidMessage: '',
      appending: false,
      appendingMessage: '',
      error: false,
      done: false,
      errorMsg: '',
      printReady: false,
      successMsg: '',
      printing: false,
      printMsg: ''
    })
  }

  async handleStickerPrint () {
    await this.handleUndo()
    const { gdir } = this.state
    const imgData = await this.generatePng()
    const base64Image = await imgData.toDataURL()
    // console.log(base64Image)

    this.setState({
      print: true,
      stickerPrint: true,
      loading: true,
      error: false,
      rfidMessage: '',
      imageData: base64Image
    })

    const rfidData = await RemotePrinter.getRfid()

    if (
      !rfidData ||
      (rfidData.hasOwnProperty('error') &&
        rfidData.error.includes('Failed to fetch'))
    ) {
      this.setState({
        loading: false,
        error: true,
        rfidMessage: `No access to the sticker printer. Printer is listening on "${
          rfidData.printerUrl
        }"`
      })
      return
    }

    if (!rfidData.tag) {
      this.setState({
        loading: false,
        error: true,
        rfidMessage:
          'NFC chip not found. Please put NFC chip near the printer and press "Try Again" button'
      })
      return
    }

    this.setState({
      loading: false,
      appending: true,
      appendingMessage: 'Appending RFID to the diploma...',
      rfidMessage: 'NFC chip found.',
      rfid: rfidData.tag
    })

    // associate rfid with the diploma
    const setRfidData = await RemoteDiploma.setDiplomaRfid(gdir, rfidData.tag)
    if (!setRfidData) {
      this.setState({
        error: true,
        errorMsg: 'Error while appending RFID. Please try again'
      })
      return
    }

    if (!setRfidData.success) {
      this.setState({
        error: true,
        errorMsg: setRfidData.message
      })
      return
    }

    if (setRfidData.success) {
      this.setState({
        printReady: true,
        // error: false,
        successMsg:
          setRfidData.message +
          ". QR Code ready! To print the sticker press 'Print QR Code Sticker' button."
      })
    }
  }

  async handlePrinting () {
    const { imageData } = this.state
    RemotePrinter.printQr(imageData)

    this.setState({
      printing: true,
      printMsg: 'Sticker is printing...'
    })
  }

  render () {
    const {
      user,
      gdir,
      imageData,
      print,
      stickerPrint,
      loading,
      appending,
      error,
      printReady,
      printing,
      rfidMessage,
      appendingMessage,
      errorMsg,
      successMsg,
      printMsg,
      qrSize
    } = this.state
    const baseUrl = window.location.origin
    const qrUrl = baseUrl + '/search?' + gdir

    return (
      <div className='details-page'>
        <div className='details-page-cover'>
          <img
            className='details-page-img'
            src={coverImage}
            alt='Details img'
          />
          <span className='title'>{printPage.title}</span>
          <div className='cover-bottom-line' />
        </div>
        <div className='content-wrapper'>
          {user.role === 'institution' ? (
            <EduProfileComponent user={user.name} edu={user.institution} />
          ) : (
            <UserProfileComponent user={user.name} />
          )}
          <div className='secondary-wrapper'>
            <div className='qr-container'>
              {!print ? (
                <div id='qr-wrapper' className='qr-wrapper'>
                  <QRCode
                    value={qrUrl}
                    level='H'
                    size={qrSize}
                    fgColor='#004a5c'
                  />
                  <p className='qr-text'>{gdir}</p>
                </div>
              ) : (
                <div id='print-wrapper'>
                  {!stickerPrint ? (
                    <ReactToPrint
                      trigger={() => (
                        <button id='hiddenBtn'>Print it....</button>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    ''
                  )}
                  {!stickerPrint ? (
                    <SendToPrinter
                      id='stp'
                      imageData={imageData}
                      ref={el => (this.componentRef = el)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              )}

              {stickerPrint && loading ? (
                <div>Loading...</div>
              ) : (
                <div className='info-text'>
                  {rfidMessage && <div>{rfidMessage}</div>}
                  {appending && <div>{appendingMessage}</div>}
                  {error && <div>{errorMsg}</div>}
                  {printReady && <div>{successMsg}</div>}
                  {printing && <div>{printMsg}</div>}
                </div>
              )}

              {!print ? (
                <div className='qr-btns'>
                  <button className='btn print-btn' onClick={this.handleLoad}>
                    Paper Print
                  </button>
                  <button
                    className='btn print-btn'
                    onClick={this.handleStickerPrint}
                  >
                    Sticker Print
                  </button>
                  <button className='btn print-btn' onClick={this.handleCancel}>
                    Go Back
                  </button>
                </div>
              ) : (
                <div className='qr-btns'>
                  {stickerPrint && error && (
                    <button
                      className='btn print-btn'
                      onClick={this.handleStickerPrint}
                    >
                      Try again
                    </button>
                  )}
                  {stickerPrint && printReady && !printing && (
                    <button
                      className='btn print-btn'
                      onClick={this.handlePrinting}
                    >
                      Print QR Code Sticker
                    </button>
                  )}
                  {!stickerPrint && (
                    <button
                      className='btn print-btn'
                      onClick={this.handlePrint}
                    >
                      Print
                    </button>
                  )}
                  {!stickerPrint && (
                    <button className='btn print-btn' onClick={this.handleUndo}>
                      Cancel
                    </button>
                  )}
                  {stickerPrint && (error || printReady) && (
                    <button className='btn print-btn' onClick={this.handleUndo}>
                      {!printing ? 'Cancel' : 'Done'}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
