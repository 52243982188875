import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Input from '../../common/forms/Input'
import registrationCoverImage from '../../../images/register/gdir_cover_profile.png'
import RemoteAuth from '../../../api/remote_auth'
// import EduRegisterForm from './EduRegisterForm'
// import AgentRegisterForm from './AgentRegisterForm'

export default class EduRegisterPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      error: '',
      eduName: '',
      uic: '',
      contractor: '',
      address: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      eduEmail: '',
      phoneNumber: '',
      printerRequest: false,
      employeeFirstName: '',
      employeeLastName: '',
      jobPosition: '',
      email: '',
      password: '',
      terms: false,
      privacy: false
    }

    this.onCancel = this.onCancel.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  onCancel () {
    this.props.history.goBack()
  }

  onChangeHandler (e) {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      [e.target.name]: value
    })
  }

  async onSubmitHandler (e) {
    e.preventDefault()
    const {
      eduName,
      uic,
      contractor,
      address,
      city,
      state,
      postalCode,
      country,
      eduEmail,
      phoneNumber,
      printerRequest,
      employeeFirstName,
      employeeLastName,
      email,
      jobPosition,
      password
    } = this.state

    let newInstitution = {
      institution: {
        edu_name: eduName,
        uic,
        contractor,
        address,
        city,
        state,
        postal_code: postalCode,
        country,
        email: eduEmail,
        phone_number: phoneNumber,
        printer_request: printerRequest
      },
      employee: {
        first_name: employeeFirstName,
        last_name: employeeLastName,
        email,
        job_position: jobPosition,
        password
      }
    }

    const res = await RemoteAuth.registerInstitution(newInstitution)
    if (!res.success) {
      return this.setState({ error: res.message })
    }
    return this.props.history.push('/auth/edu/login')
  }

  render () {
    const {
      error,
      eduName,
      eduEmail,
      uic,
      contractor,
      address,
      city,
      state,
      postalCode,
      country,
      employeeFirstName,
      employeeLastName,
      jobPosition,
      email,
      password,
      phoneNumber,
      terms,
      privacy,
      printerRequest
    } = this.state
    return (
      <div className='registration-page'>
        <div className='registration-page-cover'>
          <img
            className='registration-page-img'
            src={registrationCoverImage}
            alt='Registration img'
          />
          <span className='title'>educational institution registration</span>
          <div className='cover-bottom-line' />
        </div>
        <form className='registration-form' onSubmit={this.onSubmitHandler}>
          <span className='instructions-label'>
            It is necessary to fill all required fields for registration
          </span>
          <div>{error}</div>
          <div className='form-fields'>
            {/* <div id='institution-registration-data'> */}
            <span className='form-title'>Educational Institution Data</span>
            <div>
              <label>Institution name*</label>
              <Input
                name='eduName'
                placeholder='Institution Name'
                value={eduName}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>Unique identification code*</label>
              <Input
                name='uic'
                placeholder='Unique Identification Code'
                value={uic}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>Contractor*</label>
              <Input
                name='contractor'
                placeholder='Contractor'
                value={contractor}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>Address*</label>
              <Input
                name='address'
                placeholder='Address'
                value={address}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>City*</label>
              <Input
                name='city'
                placeholder='City'
                value={city}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>State*</label>
              <Input
                name='state'
                placeholder='State'
                value={state}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>Postal code*</label>
              <Input
                name='postalCode'
                placeholder='Postal Code'
                value={postalCode}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>Country*</label>
              <Input
                name='country'
                placeholder='Country'
                value={country}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>E-mail*</label>
              <Input
                name='eduEmail'
                placeholder='E-mail'
                value={eduEmail}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>Phone number*</label>
              <Input
                name='phoneNumber'
                placeholder='Phone number'
                value={phoneNumber}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <span className='required-label'>
                Fields marked with * are required
              </span>
            </div>
            {/* </div> */}
            <div className='checkbox-block'>
              <div id='printer-request'>
                <input
                  name='printerRequest'
                  type='checkbox'
                  checked={printerRequest}
                  onChange={this.onChangeHandler}
                />
                <label>Request hologram sticker printer</label>
              </div>
            </div>
            {/* <div id='agent-registration-data'> */}
            <span className='form-title'>Employee Data</span>
            <div>
              <label>First name*</label>
              <Input
                name='employeeFirstName'
                placeholder='First name'
                value={employeeFirstName}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>Last name*</label>
              <Input
                name='employeeLastName'
                placeholder='Last name'
                value={employeeLastName}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>Job position*</label>
              <Input
                name='jobPosition'
                placeholder='Job position'
                value={jobPosition}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>E-mail*</label>
              <Input
                type='email'
                name='email'
                placeholder='E-mail'
                value={email}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <label>Password*</label>
              <Input
                type='password'
                name='password'
                placeholder='Password'
                value={password}
                onChange={this.onChangeHandler}
                required
              />
            </div>
            <div>
              <span className='required-label'>
                Fields marked with * are required
              </span>
            </div>
            {/* </div> */}
            <div className='checkbox-block'>
              <div>
                <input
                  name='terms'
                  type='checkbox'
                  checked={terms}
                  onChange={this.onChangeHandler}
                />
                <label>
                  <span>I agree with </span>
                  <Link to='/terms'>Terms and Conditions</Link>
                </label>
              </div>
              <div>
                <input
                  name='privacy'
                  type='checkbox'
                  checked={privacy}
                  onChange={this.onChangeHandler}
                />
                <label>
                  <span>I agree with </span>
                  <Link to='/privacypolicy'>Privacy policy</Link>
                </label>
              </div>
            </div>
          </div>
          {/* <button className='cancel-button' onClick={this.onCancel} >
              Cancel
            </button> */}
          <button className='btn btn-register' disabled={!(terms && privacy)}>
            Register
          </button>
        </form>
      </div>
    )
  }
}
