import Remote from './remote'

const baseUrl = '/diploma'

export default class RemoteDiploma {
  static getAll (diploma) {
    return Remote.post(`${baseUrl}/all`, diploma)
  }

  static search (diplomaNumber) {
    return Remote.get(`${baseUrl}/search?gdir=${diplomaNumber}`, false)
  }

  static searchAuth (diplomaNumber) {
    return Remote.get(`${baseUrl}/search?gdir=${diplomaNumber}`, true)
  }

  static getDetails (id) {
    return Remote.get(`${baseUrl}/details/${id}`)
  }

  static updateStatus (id, diploma) {
    return Remote.update(`${baseUrl}/details/${id}`, diploma)
  }

  static setDiplomaRfid (gdirNumber, rfidTag) {
    return Remote.update(`${baseUrl}/rfid`, { gdir: gdirNumber, rfid: rfidTag })
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve({ success: true, message: 'All done' })
    //   }, 1300)
    // })
  }
}
