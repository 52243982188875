import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import verified from '../../images/status/verified.png'
import pending from '../../images/status/pending.png'
import rejected from '../../images/status/rejected.png'
import defaultImg from '../../images/diploma/diplomaImage.jpg'

export default class DiplomaCard extends Component {
  render () {
    const data = { ...this.props }
    const dImage = data.diploma_image_path || defaultImg
    let statusImage = verified
    if (data.verification_status === 'pending') {
      statusImage = pending
    } else if (data.verification_status === 'rejected') {
      statusImage = rejected
    }

    return (
      <div className='diploma-card'>
        <Link className='diploma-card-link' to={'/diploma/details/' + data.diploma_id}>
          <img className='diploma-card-img' src={dImage} alt='Diploma Img' />
          {/* <img src={statusImage} alt='verificationStatus' hidden={!isVerified} /> */}
          <img className='status-card-img' src={statusImage} alt='verificationStatus' />
          <div className='diploma-card-text'>
            <p>{data.institution}</p>
            <p>{data.field_of_study}</p>
            <p>{data.gdir}</p>
          </div>
        </Link>
      </div >
    )
  }
}
