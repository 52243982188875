import React, { Component } from 'react'
import UserLoginForm from './LoginForm'
import RemoteAuth from '../../../api/remote_auth'
import coverImage from '../../../images/register/gdir_cover_profile.png'

export default class UserLoginPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      error: ''
    }

    this.onCancel = this.onCancel.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  onCancel () {
    // return this.props.history.goBack()
    this.props.history.push('/')
  }

  onChangeHandler (e) {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      [e.target.name]: value
    })
  }

  async onSubmitHandler (e) {
    e.preventDefault()
    const { email, password } = this.state
    const res = await RemoteAuth.login({ email, password })
    if (res.success) {
      this.setState({ error: '' })
      window.localStorage.setItem('gdirUser', res.data.user)
      window.localStorage.setItem('gdirUsername', res.data.username)
      window.localStorage.setItem('gdirUserRole', res.data.role)
      window.localStorage.setItem('token', res.token)

      if (res.data.role === 'user') {
        return this.props.history.push('/user/profile')
      }
      return this.props.history.push('/edu/profile')
    }
    return this.setState({ error: 'Invalid data. Please try again' })
  }

  render () {
    const { email, password, error } = this.state
    const user = {
      email,
      password
    }

    return (
      <div className='login-page'>
        <div className='login-page-cover'>
          <img className='login-page-img' src={coverImage} alt='Login img' />
          <span className='title'>user login</span>
          <div className='cover-bottom-line' />
        </div>
        <UserLoginForm
          user={user}
          error={error}
          onChange={this.onChangeHandler}
          onSubmit={this.onSubmitHandler}
          onCancel={this.onCancel}
        />
      </div>
    )
  }
}
