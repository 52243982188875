import React, { Component } from 'react'
// import { diploma } from '../common/helpers/diplomaData'
import ProfileComponent from './ProfileComponent'
import DiplomasComponent from './DiplomasComponent'
import RemoteUser from '../../api/remote_user'
import coverImage from '../../images/register/gdir_cover_profile.png'
import { userHomePage } from '../common/helpers/pageText'

export default class DiplomaList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      user: {
        email: '',
        username: ''
      },
      personalDiplomas: {
        verified: [],
        pending: [],
        rejected: []
      }
    }
  }

  async componentDidMount () {
    const email = window.localStorage.getItem('gdirUser')
    const username = window.localStorage.getItem('gdirUsername')
    const personalDiplomas = await this.getData()

    const result = {
      user: {
        email,
        username
      },
      personalDiplomas
    }

    this.setState(result)
  }

  componentWillUnmount () {
    this.setState({
      user: {
        email: '',
        username: ''
      },
      personalDiplomas: {
        verified: [],
        pending: [],
        rejected: []
      }
    })
  }

  async getData () {
    const verifiedPromise = RemoteUser.getVerifiedDiplomas()
    const pendingPromise = RemoteUser.getPendingDiplomas()
    const rejectedPromise = RemoteUser.getRejectedDiplomas()

    const verified = await verifiedPromise
    const pending = await pendingPromise
    const rejected = await rejectedPromise

    return {
      verified: verified.data,
      pending: pending.data,
      rejected: rejected.data
    }
  }
  render () {
    const { user, personalDiplomas } = this.state

    return (
      <div className='user-home-page'>
        <div className='user-home-page-cover'>
          <img
            className='user-home-page-img'
            src={coverImage}
            alt='User home page img'
          />
          <span className='title'>{userHomePage.title}</span>
          <div className='cover-bottom-line' />
        </div>
        <div className='content-wrapper'>
          <ProfileComponent user={user.username} />
          <div className='secondary-wrapper'>
            <DiplomasComponent diplomas={personalDiplomas} />
          </div>
        </div>
      </div>
    )
  }
}
