import React, { Component } from 'react'
import { getDateOnly, getTimeOnly } from '../common/helpers/helpers'

export default class LastVerifiedDiplomas extends Component {
  render () {
    const { diploma } = this.props

    return (
      <div className='last-verified-diplomas'>
        <fieldset>
          <legend>Last verified diplomas</legend>
          <div className='last-verified-diplomas-content'>
            {diploma && diploma.length > 0 ? (
              diploma.map(d => {
                const date = getDateOnly(d.verification_date)
                const time = getTimeOnly(d.verification_date)
                return (
                  <div key={d.diploma_id}>
                    {d.owner} / {d.gdir} / {date} ({time})
                  </div>
                )
              })
            ) : (
              <div>Verified diplomas not found!</div>
            )}
          </div>
        </fieldset>
      </div>
    )
  }
}
