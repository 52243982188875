import React, { Component } from 'react'
import Input from '../common/forms/Input'

export default class CreateDiplomaUserForm extends Component {
  render () {
    const { diploma, error, onChange, onSubmit } = this.props
    return (
      <form className='register-form' onSubmit={onSubmit}>
        <span className='instructions-label'>It is necessary to fill all required fields for registration</span>
        <div>{error}</div>
        <div className='form-fields'>
          <div>
            <label>Educational Institution*</label>
            <Input
              name='institution'
              placeholder='Educational Institution'
              value={diploma.institution}
              onChange={onChange}
              required />
          </div>
          <div>
            <label>Person names*</label>
            <Input
              name='owner'
              placeholder='Person names'
              value={diploma.owner}
              onChange={onChange}
              required />
          </div>
          <div>
            <label>Degree*</label>
            <Input
              name='degree'
              placeholder='Degree'
              value={diploma.degree}
              onChange={onChange}
              required />
          </div>
          <div>
            <label>Grade*</label>
            <Input
              name='grade'
              placeholder='Grade'
              value={diploma.grade}
              onChange={onChange}
              required />
          </div>
          <div>
            <label>Field of study*</label>
            <Input
              name='fieldOfStudy'
              placeholder='Field of study'
              value={diploma.fieldOfStudy}
              onChange={onChange}
              required />
          </div>
          <div>
            <label>Professional qualification*</label>
            <Input
              name='professionalQualification'
              placeholder='Professional qualification'
              value={diploma.professionalQualification}
              onChange={onChange}
              required />
          </div>
          <div>
            <label>Registration number*</label>
            <Input
              name='registrationNumber'
              placeholder='Registration number'
              value={diploma.registrationNumber}
              onChange={onChange}
              required />
          </div>
          <div>
            <label>Image URL</label>
            <Input
              name='image'
              type='url'
              placeholder='Image url'
              value={diploma.image}
              onChange={onChange} />
          </div>
          <div>
            <label>Issue date*</label>
            <Input
              name='issueDate'
              type='date'
              placeholder='Issue Date'
              value={diploma.issueDate}
              onChange={onChange}
              required />
          </div>
          <div>
            <span className='required-label'>Fields marked with * are required</span>
          </div>
          <div className='checkbox-block'>
            <div>
              <input name='verificationRequest' type='checkbox' checked={diploma.verificationRequest} onChange={onChange} />
              <label>Send diploma verification request</label>
            </div>
          </div>
        </div>
        <button className='btn btn-submit'>Submit</button>
      </form>
    )
  }
}
