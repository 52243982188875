import React, { Component } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import LanguageSelector from './LanguageSelector'
import LoginMenu from './LoginMenu'
import logoImg from '../../images/logo/logo-2.png'
// import logo from '../../images/logo/page-1.png'
// import logo2x from '../../images/logo/page-1@2x.png'
// import logo3x from '../../images/logo/page-1@3x.png'
// import { logout } from '../../api/fakeApi'

class Header extends Component {
  constructor (props) {
    super(props)

    this.logout = this.logout.bind(this)
    this.routeRedirect = this.routeRedirect.bind(this)
  }

  logout () {
    window.localStorage.clear()
    this.props.history.push('/')
  }

  routeRedirect () {
    const user = window.localStorage.getItem('gdirUser')
    let role = window.localStorage.getItem('gdirUserRole')

    if (role === 'institution') {
      role = 'edu'
    }

    const homePath = user && role ? `/${role}/profile` : '/'

    return this.props.history.push(homePath)
  }

  render () {
    // let role = window.localStorage.getItem('gdirUserRole')
    let user = window.localStorage.getItem('gdirUser')

    return (
      <header id='site-header' className='header'>
        {/* <img className='logo' src={logo} srcSet={`${logo}, ${logo2x}, ${logo3x}`} alt='GDIR logo' /> */}
        <img className='logo' src={logoImg} alt='GDIR logo' onClick={this.routeRedirect} />
        <nav className='navbar'>
          {
            user
              ? <ul id='navbar-logged-in' className='navbar-menu'>
                <li>
                  <NavLink activeClassName='active' to='/search'>Search Diploma</NavLink>
                </li>
                <li id='lang-selector'>
                  <LanguageSelector />
                </li>
                <li>
                  <span id='logged-user'>
                    {user}
                  </span>
                </li>
                <li>
                  <Link to='#' onClick={this.logout}>Logout</Link>
                </li>
              </ul>
              : <ul className='navbar-menu'>
                {/* <li><button className='btn btn-login-toggle' onClick={this.showMenu}>Login</button></li>
                <li> */}
                <li>
                  <LoginMenu />
                </li>
                <li id='lang-selector'>
                  <LanguageSelector />
                </li>
              </ul>
          }
        </nav>
      </header >
    )
  }
}

export default withRouter(Header)
