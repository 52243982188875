import React, { Component } from 'react'

export default class Input extends Component {
  render() {
    const { type = 'text', required = false, name, placeholder, value, onChange, className = '' } = this.props

    return (
      <input
        className={className}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
      />
    )
  }
}
