import React, { Component } from 'react'
import Input from '../common/forms/Input'

export default class SearchBox extends Component {
  render () {
    const {
      value,
      error,
      isVisible,
      placeholder = 'Please enter GDIR number for a quick reference',
      onChange,
      onSubmit
    } = this.props

    return (
      // <form className='search-form' onSubmit={onSubmit}>
      //   <Input
      //     className='search-input'
      //     name='search'
      //     placeholder={placeholder}
      //     value={value}
      //     onChange={onChange}
      //   />
      //   <button className='btn btn-search' type='submit'>Search</button>
      // </form>
      <div className='search-container'>
        <div className='search-error' hidden={!isVisible}>{error}</div>
        <form className='search-form' onSubmit={onSubmit}>
          <Input
            className='search-input'
            name='search'
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
          <button className='btn btn-search'
            type='submit'>Search</button>
        </form>
      </div>
    )
  }
}
