import React, { Component } from 'react'
import ProfileComponent from './ProfileComponent'
import DiplomaComponent from './DiplomasComponent'
import userImg from '../../images/user.png'
import { eduHomePage } from '../common/helpers/pageText'
import coverImage from '../../images/register/gdir_cover_profile.png'
import RemoteInstitution from '../../api/remote_institution'

export default class ProfilePage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      user: {
        email: '',
        username: '',
        institution: ''
      },
      diploma: {
        lastVerified: [],
        pending: []
      }
    }
  }

  componentDidMount () {
    this.getData()
  }

  componentWillUnmount () {
    this.setState({
      diploma: {
        lastVerified: [],
        pending: []
      }
    })
  }

  async getData () {
    const email = window.localStorage.getItem('gdirUser')
    const username = window.localStorage.getItem('gdirUsername')
    const institution = window.localStorage.getItem('gdirInstitutionName')
    let lastVerifiedDiplomas = 'No diplomas to show'
    let pendingDiplomas = 'No diplomas to show'

    const lastVerifiedData = await RemoteInstitution.getLastVerifiedDiplomas()
    const pendingData = await RemoteInstitution.getPendingDiplomas()

    if (lastVerifiedData.success) {
      lastVerifiedDiplomas = lastVerifiedData.data
    }

    if (pendingData.success) {
      pendingDiplomas = pendingData.data
    }

    this.setState({
      user: {
        username,
        email,
        institution
      },
      diploma: {
        lastVerified: lastVerifiedDiplomas,
        pending: pendingDiplomas
      }
    })
  }
  render () {
    const { username, institution } = this.state.user
    const { diploma } = this.state

    return (
      <div className='edu-home-page'>
        <div className='edu-home-page-cover'>
          <img
            className='edu-home-page-img'
            src={coverImage}
            alt='Edu home page img'
          />
          <span className='title'>{eduHomePage.title}</span>
          <div className='cover-bottom-line' />
        </div>
        <div className='content-wrapper'>
          <ProfileComponent
            userImg={userImg}
            user={username}
            edu={institution}
          />
          <div className='diploma-wrapper'>
            <DiplomaComponent diploma={diploma} />
          </div>
        </div>
      </div>
    )
  }
}
