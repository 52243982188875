import React, { Component } from 'react'
import CreateDiplomaUserForm from './CreateDiplomaUserForm'
import { diplomaPage } from '../common/helpers/pageText'
import ProfileComponent from '../Users/ProfileComponent'
import coverImage from '../../images/register/gdir_cover_profile.png'
import RemoteUser from '../../api/remote_user'

export default class CreateDiplomaUser extends Component {
  constructor (props) {
    super(props)

    this.state = {
      institution: '',
      owner: '',
      degree: '',
      grade: '',
      fieldOfStudy: '',
      professionalQualification: '',
      registrationNumber: '',
      image: '',
      issueDate: '',
      gdir: '',
      verificationRequest: false,
      user: {
        email: '',
        name: ''
      },
      error: ''
    }

    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  componentDidMount () {
    this.setData()
  }

  async setData () {
    const user = window.localStorage.getItem('gdirUser')
    const username = window.localStorage.getItem('gdirUsername')

    this.setState({
      user: {
        email: user,
        name: username
      }
    })
  }

  onChangeHandler (e) {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({ [e.target.name]: value })
  }

  async onSubmitHandler (e) {
    e.preventDefault()

    const {
      institution,
      owner,
      degree,
      grade,
      fieldOfStudy,
      professionalQualification,
      registrationNumber,
      image,
      issueDate,
      gdir,
      verificationRequest
    } = this.state
    const data = {
      institution,
      owner,
      degree,
      grade,
      field_of_study: fieldOfStudy,
      professional_qualification: professionalQualification,
      registration_number: registrationNumber,
      diploma_image_path: image,
      issue_date: issueDate,
      gdir: gdir,
      verification_request: verificationRequest
    }

    const addDiploma = await RemoteUser.addDiploma(data)

    if (addDiploma.success) {
      return this.props.history.push('/user/profile')
    }
  }

  render () {
    const {
      institution,
      owner,
      degree,
      grade,
      fieldOfStudy,
      professionalQualification,
      registrationNumber,
      image,
      issueDate,
      gdir,
      verificationRequest,
      error
    } = this.state
    const { name } = this.state.user

    const diplomaData = {
      institution,
      owner,
      degree,
      grade,
      fieldOfStudy,
      professionalQualification,
      registrationNumber,
      image,
      issueDate,
      gdir,
      verificationRequest
    }
    return (
      <div className='diploma-create-page'>
        <div className='diploma-create-page-cover'>
          <img
            className='diploma-create-page-img'
            src={coverImage}
            alt='Diploma page img'
          />
          <span className='title'>{diplomaPage.title}</span>
          <div className='cover-bottom-line' />
        </div>
        <div className='content-wrapper'>
          <ProfileComponent user={name} />
          <div className='secondary-wrapper'>
            <CreateDiplomaUserForm
              diploma={diplomaData}
              error={error}
              onChange={this.onChangeHandler}
              onSubmit={this.onSubmitHandler}
            />
          </div>
        </div>
      </div>
    )
  }
}
