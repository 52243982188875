import Remote from './remote'

const baseUrl = '/edu'

export default class RemoteInstitution {
  static getAll () {
    return Remote.get(`${baseUrl}/all`)
  }

  static getInstitutionData () {
    return Remote.get(`${baseUrl}/edit`)
  }

  static editInstitution (institution) {
    return Remote.update(`${baseUrl}/edit`, institution)
  }

  static addDiploma (diploma) {
    return Remote.post(`${baseUrl}/newdiploma`, diploma)
  }

  static getVerifiedDiplomas () {
    return Remote.get(`${baseUrl}/verified`)
  }

  static getPendingDiplomas () {
    return Remote.get(`${baseUrl}/pending`)
  }

  static getLastVerifiedDiplomas () {
    return Remote.get(`${baseUrl}/lastverified`)
  }
}
