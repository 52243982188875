import React, { Component } from 'react'

export default class UsersPage extends Component {
  constructor (props) {
    super(props)

    this.handleLoginClick = this.handleLoginClick.bind(this)
    this.handleRegisterClick = this.handleRegisterClick.bind(this)
  }

  handleRegisterClick (e) {
    return this.props.history.push('/auth/user/register')
  }

  handleLoginClick (e) {
    // return this.props.history.push('/auth/user/login')
    return this.props.history.push('/auth/user/login')
  }

  render () {
    return (
      <div>
        <h1>Users page</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in orci sit amet ante vestibulum euismod pretium eu quam. Aliquam vitae ultricies mauris. Phasellus a tempor ex. Suspendisse potenti. In pharetra, metus ut maximus vestibulum, enim est sodales enim, consequat accumsan tellus enim sed est. Sed dui augue, malesuada non tempor non, ullamcorper sed nibh. Mauris sit amet odio at dolor fringilla venenatis. Etiam eros odio, condimentum vel justo ut, fringilla consectetur augue.</p>

        <input type='button' value='Login' onClick={this.handleLoginClick} />
        <input type='button' value='Register' onClick={this.handleRegisterClick} />
      </div>
    )
  }
}
