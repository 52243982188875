import React, { Component } from 'react'
import Input from '../../common/forms/Input'
import registrationCoverImage from '../../../images/register/gdir_cover_profile.png'
import RemoteUser from '../../../api/remote_user'
import { updateLocalStorage } from '../../common/helpers/helpers'

export default class EduRegisterPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      error: '',
      firstName: '',
      lastName: '',
      jobPosition: '',
      email: '',
      id: '',
      role: ''
    }

    this.onCancel = this.onCancel.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  componentDidMount () {
    this.getData()
  }

  async getData () {
    const email = window.localStorage.getItem('gdirUser')

    const getUserData = await RemoteUser.getUserData({ email })
    if (getUserData.success) {
      const userData = getUserData.data

      return this.setState({
        firstName: userData.first_name,
        lastName: userData.last_name,
        jobPosition: userData.job_position,
        ...userData
      })
    }
  }

  onCancel () {
    this.props.history.goBack()
  }

  onChangeHandler (e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      [e.target.name]: value
    })
  }

  async onSubmitHandler (e) {
    e.preventDefault()
    const { firstName, lastName, email, jobPosition, id, role } = this.state
    const newData = {
      first_name: firstName,
      last_name: lastName,
      job_position: jobPosition,
      email,
      id,
      role
    }
    const editUser = await RemoteUser.editUser(newData)

    if (editUser.success) {
      const { email, username } = editUser.data
      updateLocalStorage(email, username)
      return this.props.history.push('/edu/profile')
    }
  }

  render () {
    const {
      error,
      firstName,
      lastName,
      jobPosition,
      email
    } = this.state
    return (
      <div className='registration-page'>
        <div className='registration-page-cover'>
          <img className='registration-page-img' src={registrationCoverImage} alt='Registration img' />
          <span className='title'>edit employee profile</span>
          <div className='cover-bottom-line' />
        </div>
        <form className='registration-form' onSubmit={this.onSubmitHandler}>
          <div>{error}</div>
          <div className='form-fields'>
            <div>
              <label>First name</label>
              <Input
                name='firstName'
                placeholder='First name'
                value={firstName}
                onChange={this.onChangeHandler}
                required />
            </div>
            <div>
              <label>Last name</label>
              <Input
                name='lastName'
                placeholder='Last name'
                value={lastName}
                onChange={this.onChangeHandler}
                required />
            </div>
            <div>
              <label>Job position</label>
              <Input
                name='jobPosition'
                placeholder='Job position'
                value={jobPosition}
                onChange={this.onChangeHandler}
                required />
            </div>
            <div>
              <label>Email</label>
              <Input
                name='email'
                placeholder='Phone number'
                value={email}
                onChange={this.onChangeHandler}
                required />
            </div>
          </div>
          <button className='btn btn-register'>Edit</button>
        </form>
      </div>
    )
  }
}
