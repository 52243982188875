import React, { Component } from "react"
import { terms } from "../common/helpers/pageText"
import coverImage from "../../images/register/gdir_cover_profile.png"

export default class TermsAndConditions extends Component {
  render() {
    return (
      <div className="terms-page">
        <div className="terms-page-cover">
          <img className="terms-page-img" src={coverImage} alt="Terms img" />
          <span className="title">{terms.title}</span>
          <div className="cover-bottom-line" />
        </div>
        <div className="page-content">
          <div>
            <div className="question-box">
              <span className="question">{terms.firstParagraph.t1.title}</span>
            </div>
            <p>{terms.firstParagraph.t1.p1}</p>
          </div>
          <div>
            <div className="question-box">
              <span className="question">{terms.firstParagraph.t2.title}</span>
            </div>
            <p>{terms.firstParagraph.t2.p1}</p>
            <p>{terms.firstParagraph.t2.p2}</p>
            <p>{terms.firstParagraph.t2.p3}</p>
            <p>{terms.firstParagraph.t2.p4}</p>
            <p>{terms.firstParagraph.t2.p5}</p>
          </div>
          <div>
            <div className="question-box">
              <span className="question">{terms.secondParagraph.t1.title}</span>
            </div>
            <p>{terms.secondParagraph.t1.p1}</p>
            <p>{terms.secondParagraph.t1.p2}</p>
            <p>{terms.secondParagraph.t1.p3}</p>
            <p>{terms.secondParagraph.t1.p4}</p>
            <p>{terms.secondParagraph.t1.p5}</p>
          </div>
          <p>{terms.secondParagraph.t1.p6}</p>
          <p>{terms.secondParagraph.t1.p7}</p>
        </div>
      </div>
    )
  }
}
