import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ListDiplomas from '../Diploma/ListDiplomas'

export default class DiplomaComponent extends Component {
  render () {
    const { verified, pending, rejected } = this.props.diplomas
    const limit = 4

    return (
      <div className='diploma-component'>
        <fieldset className='verified-diplomas'>
          <legend>Verified diplomas</legend>
          <div className='diploma-section-div'>
            <ListDiplomas diploma={verified} limit={limit} />
            <div className='diploma-all-link'>
              <Link to='/diploma/all?status=verified'>Show all</Link>
              {/* <Link to='/user/verified'>Show all</Link> */}
            </div>
          </div>
        </fieldset>
        <fieldset className='pending-diplomas'>
          <legend>Pending diplomas</legend>
          <div className='diploma-section-div'>
            <ListDiplomas diploma={pending} limit={limit} />
            <div className='diploma-all-link'>
              <Link to='/diploma/all?status=pending'>Show all</Link>
              {/* <Link to='/user/pending'>Show all</Link> */}
            </div>
          </div>
        </fieldset>
        <fieldset className='rejected-diplomas'>
          <legend>Rejected diplomas</legend>
          <div className='diploma-section-div'>
            <ListDiplomas diploma={rejected} limit={limit} />
            <div className='diploma-all-link'>
              <Link to='/diploma/all?status=rejected'>Show all</Link>
              {/* <Link to='/user/rejected'>Show all</Link> */}
            </div>
          </div>
        </fieldset>
      </div>
    )
  }
}
