import React, { Component } from 'react'
import DiplomaCard from './DiplomaCard'
// import { Link } from 'react-router-dom'
// import { diploma } from '../common/diplomaData'

export default class ListDiplomas extends Component {
  render () {
    const { diploma, limit = 0 } = this.props
    let data = diploma

    if (data && limit > 0) {
      data = diploma.slice(0, limit)
    }
    // const data = diploma.filter(d => d.verificationStatus === 'verified')
    return (
      <div className='diploma-list'>
        {
          data && data.length > 0
            ? data.map(d => {
              // const link = `/details/${d.id}`
              return (
                // <Link to={link}>
                //   <DiplomaCard
                //     key={d.id}
                //     {...d}
                //   />
                // </Link>
                <DiplomaCard
                  key={d.diploma_id}
                  {...d}
                />
              )
            })
            : <span className='no-diplomas'>No diplomas found.</span>}

      </div>

    )
  }
}
