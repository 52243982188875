import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
// import DiplomaEduCard from '../Diploma/DiplomaEduCard'
import LastVerifiedDiplomas from '../Diploma/LastVerifiedDiplomas'
import ListEduDiplomas from '../Diploma/ListEduDiplomas'
// import ProfileComponent from './ProfileComponent'

export default class DiplomaComponent extends Component {
  render () {
    const { diploma } = this.props

    return (
      <div className='edu-diploma-wrapper'>
        <div className='edu-diploma-component'>
          <div className='edu-profile-diploma-btns'>
            <NavLink
              className='btn'
              activeClassName='active'
              to='/edu/newdiploma'
            >
              Add diploma
            </NavLink>
            <NavLink
              className='btn'
              activeClassName='active'
              to='/edu/verified'
            >
              Verified diplomas
            </NavLink>
          </div>
          <LastVerifiedDiplomas diploma={diploma.lastVerified} />
        </div>
        <div className='pending-diplomas'>
          <div className='pending-title'>
            <span>Pending diplomas</span>
          </div>
          <ListEduDiplomas diploma={diploma.pending} />
          {/* {diploma.map(d => {
            return (
              <DiplomaEduCard
                key={d.id}
                id={d.id}
                owner={d.owner}
              />
            )
          })} */}
        </div>
      </div>
    )
  }
}
