import React, { Component } from 'react'
// import ReactToPrint from 'react-to-print'

export default class SendToPrinter extends Component {
  render () {
    const { imageData } = this.props

    if (imageData) {
      return <img src={imageData} alt='qr code' />
    }
    return <p>Nothing to print</p>
  }
}
