import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class DiplomaEduCard extends Component {
  render () {
    const { ...diploma } = this.props

    return (
      <div className='diploma-edu-card'>
        <div id='verified-wrapper'>
          <span className='verified-diploma-text'>{diploma.owner}</span>
          <span id='verified-diploma-gdir' className='verified-diploma-text'>
            GDIR Number: {diploma.gdir}
          </span>
        </div>
        <Link className='btn' to={'/edu/details/' + diploma.diploma_id}>
          View
        </Link>
      </div>
    )
  }
}
