import React, { Component } from 'react'
import SearchBox from './SearchBox'
import logo from '../../images/logo/logo-1.png'
import verifiedImage from '../../images/status/verified.png'
import pendingImage from '../../images/status/pending.png'
import rejectedImage from '../../images/status/rejected.png'
import RemoteDiploma from '../../api/remote_diploma'

export default class SearchPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      search: '',
      user: '',
      diploma: {},
      error: ''
    }

    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  async componentDidMount () {
    const userEmail = window.localStorage.getItem('gdirUser')
    const searchData = this.props.location.search.slice(1)
    let data = {
      user: userEmail,
      diploma: {}
    }

    if (searchData) {
      data.diploma = await this.getData(searchData)
    }
    this.setState(data)
  }

  async getData (searchData) {
    const token = window.localStorage.getItem('token')
    let diplomaData
    if (token) {
      diplomaData = await RemoteDiploma.searchAuth(searchData)
    } else {
      diplomaData = await RemoteDiploma.search(searchData)
    }

    // console.log(diplomaData)

    // if (diplomaData.success) {
    //   this.setState({
    //     diploma: diplomaData.data
    //   })
    // }

    return diplomaData
  }

  onChangeHandler (e) {
    this.setState({
      [e.target.name]: e.target.value.toUpperCase().trim()
    })
  }

  async onSubmitHandler (e) {
    e.preventDefault()
    const searchValue = e.target.search.value.trim()
    if (searchValue) {
      const diplomaData = await this.getData(searchValue)
      this.setState({
        diploma: diplomaData
      })

      if (diplomaData.success) {
        this.setState({
          error: ''
        })
      }

      return this.props.history.push('/search?' + searchValue)
    }
    this.setState({
      error: 'Please enter GDIR number in search field!'
    })
  }

  render () {
    const { user, diploma } = this.state
    const searchData = this.props.location.search.slice(1)

    let isVisible = this.state.error || this.state.error !== ''
    let statusImage

    if (diploma.success && diploma.data.verification_status) {
      if (diploma.data.verification_status === 'verified') {
        statusImage = verifiedImage
      } else if (diploma.data.verification_status === 'pending') {
        statusImage = pendingImage
      } else if (diploma.data.verification_status === 'rejected') {
        statusImage = rejectedImage
      }
    }

    return (
      <section className='search-page-section'>
        <div className='search-box-info'>
          <img
            className='logo-search'
            src={logo}
            alt='GDIR search diploma logo'
          />
          <p className='search-desc'>Global Diploma Identification Register</p>
        </div>
        <div className='cover-bottom-line cover-bottom-line-search'>
          {searchData !== '' ? (
            <p>
              Search result for GDIR number{' '}
              <span id='search-data'>{searchData}</span>
            </p>
          ) : null}
        </div>
        <SearchBox
          value={this.state.search}
          error={this.state.error}
          isVisible={isVisible}
          onChange={this.onChangeHandler}
          onSubmit={this.onSubmitHandler}
        />
        {searchData ? (
          diploma.success ? (
            user ? (
              <div className='diploma-search-page'>
                <div className='search-gdir-number'>
                  <span>GDIR number</span>
                  <span id='gdir-number-bold'>{diploma.data.gdir}</span>
                </div>
                <div className='search-verification'>
                  <img
                    className='status-image'
                    src={statusImage}
                    alt='status img'
                  />
                  {/* <span>{result.verificationStatus}</span> */}
                  <span id='search-institution'>{diploma.data.edu_name}</span>
                </div>
                <div id='outer-diploma-div'>
                  <div id='inner-diploma-div'>
                    {diploma.data.image ? (
                      <img
                        className='diploma-image'
                        src={diploma.data.image}
                        alt='diploma img'
                      />
                    ) : (
                      <div>NO IMAGE TO SHOW</div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className='diploma-search-page'>
                <div className='search-gdir-number'>
                  <span>GDIR number</span>
                  <span id='gdir-number-bold'>{diploma.data.gdir}</span>
                </div>
                <div className='search-verification'>
                  <img
                    className='status-image'
                    src={statusImage}
                    alt='status img'
                  />
                  {/* <span>{result.verificationStatus}</span> */}
                  <span id='search-institution'>{diploma.data.edu_name}</span>
                </div>
              </div>
            )
          ) : (
            <div className='diploma-search-page'>
              <p>
                Diploma NOT found! Please check the GDIR number and try again.
              </p>
            </div>
          )
        ) : (
          <div className='diploma-search-page'>No search data provided!</div>
        )}
      </section>
    )
  }
}
