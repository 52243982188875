import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class Footer extends Component {
  render() {
    return (
      <footer id='site-footer' className='footer'>
        <div className='footer-wrapper'>
          <ul className='footer-main-nav'>
            <li>
              <NavLink className='a' to='/about' >About</NavLink>
            </li>
            <li>
              <NavLink className='a' to='/faq' >FAQ</NavLink>
            </li>
            {/* <li>
            <NavLink className='a' to='/institutions' >Educational Institutions</NavLink>
            </li> */}
            <li>
              <NavLink className='a' to='/printer' >Hologram Sticker Printer</NavLink>
            </li>
            <li>
              <NavLink className='a' to='/contact' >Contact</NavLink>
            </li>
          </ul>
          <ul className='footer-sec-nav'>
            <li>
              <NavLink className='a' to='/terms' >Terms and Conditions of use</NavLink>
            </li>
            <li>
              <NavLink className='a' to='/privacypolicy' >Privacy Policy</NavLink>
            </li>
          </ul>
          <p className='trade-mark'>&copy; 2018 Digimark International</p>
        </div>
      </footer>
    )
  }
}
