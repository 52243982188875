import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Input from '../../common/forms/Input'

export default class UserRegisterForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      terms: false,
      privacy: false
    }

    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  onChangeHandler (e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      [e.target.name]: value
    })
  }

  render () {
    const { user, error, onChange, onSubmit } = this.props
    const { terms, privacy } = this.state

    return (
      <form className='registration-form' onSubmit={onSubmit}>
        <span className='instructions-label'>It is necessary to fill all required fields for registration</span>
        <div>{error}</div>
        <div className='form-fields'>
          <div>
            <label>First name*</label>
            <Input
              name='firstName'
              placeholder='First Name'
              value={user.firstName}
              onChange={onChange}
              required />
          </div>
          <div>
            <label>Last name*</label>
            <Input
              name='lastName'
              placeholder='Last Name'
              value={user.lastName}
              onChange={onChange}
              required />
          </div>
          <div>
            <label>E-mail*</label>
            <Input
              type='email'
              name='email'
              placeholder='E-mail'
              value={user.email}
              onChange={onChange}
              required />
          </div>
          <div>
            <label>Password*</label>
            <Input
              type='password'
              name='password'
              placeholder='Password'
              value={user.password}
              onChange={onChange}
              required />
          </div>
          <div>
            <label>Confirm password*</label>
            <Input
              type='password'
              name='confirmPassword'
              placeholder='Confirm Password'
              value={user.confirmPassword}
              onChange={onChange}
              required />
          </div>
          <div>
            <span className='required-label'>Fields marked with * are required</span>
          </div>

          <div className='checkbox-block'>
            <div>
              <input name='terms' type='checkbox' checked={terms} onChange={this.onChangeHandler} />
              <label>
                <span>I agree with </span>
                <Link to='/terms'>Terms and Conditions</Link>
              </label>
            </div>
            <div>
              <input name='privacy' type='checkbox' checked={privacy} onChange={this.onChangeHandler} />
              <label>
                <span>I agree with </span>
                <Link to='/privacypolicy'>Privacy policy</Link>
              </label>
            </div>
          </div>
          {/* <input type='button' value='Cancel' onClick={onCancel} /> */}
          <button className='btn btn-register' disabled={!(terms && privacy)}>Register</button>
        </div>
      </form >
    )
  }
}
