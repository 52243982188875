import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { getDateOnly } from '../common/helpers/helpers'
import defaultDiplomaImage from '../../images/diploma/diplomaImage.jpg'
import RemoteDiploma from '../../api/remote_diploma'

class DiplomaPreview extends Component {
  constructor (props) {
    super(props)

    this.state = {
      user: {
        email: ''
      }
    }

    this.handleCancel = this.handleCancel.bind(this)
    this.handleApprove = this.handleApprove.bind(this)
    this.handleReject = this.handleReject.bind(this)
    this.printGdir = this.printGdir.bind(this)
  }

  componentDidMount () {
    const userEmail = window.localStorage.getItem('gdirUser')

    this.setState({
      user: {
        email: userEmail
      }
    })
  }

  async changeDiplomaProps (id, status) {
    const { email } = this.state.user
    const updateData = {
      employee: email,
      status: status
    }
    const updateStatus = await RemoteDiploma.updateStatus(id, updateData)

    if (updateStatus.success) {
      return this.props.history.push('/edu/profile')
    }
  }

  handleCancel () {
    return this.props.history.goBack()
  }

  async handleApprove () {
    const id = this.props.diploma.diploma_id
    this.changeDiplomaProps(id, 'verified')
  }

  handleReject () {
    const id = this.props.diploma.diploma_id
    this.changeDiplomaProps(id, 'rejected')
  }

  printGdir () {
    const gdir = this.props.diploma.gdir
    return this.props.history.push(`/print?${gdir}`)
  }

  render () {
    const { diploma, login = 'user' } = this.props
    const data = diploma

    const image = data.diploma_image_path || defaultDiplomaImage
    return (
      <div className='diploma-details-container'>
        <fieldset>
          <legend>{data.owner}</legend>
          <div className='diploma-details-wrapper'>
            <div className='diploma-details'>
              <div className='diploma-details-description'>
                <p>{data.institution}</p>
                <p>{data.degree}</p>
                <p>{data.field_of_study}</p>
                <p>{data.professional_qualification}</p>
                <p>{data.registration_number}</p>
                <p>{getDateOnly(data.issue_date)}</p>
                {data.gdir ? <p>{data.gdir}</p> : ''}
                {data.gdir && login === 'institution' ? (
                  <button
                    className='btn print-gdir-btn'
                    onClick={this.printGdir}
                  >
                    Print GDIR Number
                  </button>
                ) : (
                  ''
                )}
              </div>
              <img className='diploma-details-img' src={image} alt='img' />
            </div>
            {login === 'institution' ? (
              !data.gdir ? (
                <div className='action-buttons'>
                  <button className='btn' onClick={this.handleCancel}>
                    Cancel
                  </button>
                  <button
                    className='btn btn-reject'
                    onClick={this.handleReject}
                  >
                    Reject
                  </button>
                  <button
                    className='btn btn-approve'
                    onClick={this.handleApprove}
                  >
                    Approve
                  </button>
                </div>
              ) : (
                <div>
                  <button className='btn' onClick={this.handleCancel}>
                    Close
                  </button>
                </div>
              )
            ) : (
              <div>
                <button className='btn' onClick={this.handleCancel}>
                  Close
                </button>
              </div>
            )}
          </div>
        </fieldset>
      </div>
    )
  }
}

export default withRouter(DiplomaPreview)
