import React, { Component } from 'react'
import DiplomaEduCard from './DiplomaEduCard'

export default class ListDiplomas extends Component {
  render () {
    const { diploma } = this.props

    return (
      <div className='diploma-edu-list'>
        {diploma && diploma.length > 0 ? (
          diploma.map(d => {
            return <DiplomaEduCard key={d.diploma_id} {...d} />
          })
        ) : (
          <div>No diplomas found!</div>
        )}
      </div>
    )
  }
}
