import React, { Component } from 'react'

export default class FaqBox extends Component {
  render() {
    const { data } = this.props
    const question = data.question
    const answer = data.answer
    return (
      <div className='question-box'>
        <span className='question'>{question}</span>
        <p className='answer'>{answer}</p>
      </div>
    )
  }
}
