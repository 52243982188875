import React, { Component } from 'react'
import { privacy } from '../common/helpers/pageText'
import coverImage from '../../images/register/gdir_cover_profile.png'

export default class PrivacyPolicy extends Component {
  render () {
    return (
      <div className='privacy-page'>
        <div className='privacy-page-cover'>
          <img
            className='privacy-page-img'
            src={coverImage}
            alt='Privacy img'
          />
          <span className='title'>{privacy.title}</span>
          <div className='cover-bottom-line' />
        </div>
        <div className='page-content'>
          <div>
            <div className='question-box'>
              <span className='question'>
                {privacy.firstParagraph.t1.title}
              </span>
            </div>
            <p>{privacy.firstParagraph.t1.p1}</p>
            <p>{privacy.firstParagraph.t1.p2}</p>
            <p>{privacy.firstParagraph.t1.p3}</p>
            <p>{privacy.firstParagraph.t1.p4}</p>
            <p>{privacy.firstParagraph.t1.p5}</p>
            <div>
              <p>{privacy.firstParagraph.t1.p6}</p>
              <p>{privacy.firstParagraph.t1.p7}</p>
              <p>{privacy.firstParagraph.t1.p8}</p>
            </div>
            <p>{privacy.firstParagraph.t1.p9}</p>
          </div>
        </div>
      </div>
    )
  }
}
