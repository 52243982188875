import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import defaultUserImg from '../../images/user.png'

export default class ProfileComponent extends Component {
  render () {
    const { userImg = defaultUserImg, user } = this.props

    return (
      <div className='profile-section'>
        <div className='user-profile'>
          <img src={userImg} alt='User img' />
          <p>{user}</p>
          <div className='profile-links'>
            <NavLink activeClassName='active' to='/user/edit'>
              Edit User Profile
            </NavLink>
            <NavLink activeClassName='active' to='/user/newdiploma'>
              Add new diploma
            </NavLink>
          </div>
        </div>
      </div>
    )
  }
}
