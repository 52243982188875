import React, { Component } from 'react'
import Input from '../../common/forms/Input'
import registrationCoverImage from '../../../images/register/gdir_cover_profile.png'
import RemoteInstitution from '../../../api/remote_institution'

export default class EduRegisterPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      error: '',
      eduName: '',
      uic: '',
      contractor: '',
      address: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      eduEmail: '',
      phoneNumber: '',
      id: ''
    }

    this.onCancel = this.onCancel.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  componentDidMount () {
    this.getData()
  }

  async getData () {
    const email = window.localStorage.getItem('gdirUser')

    const getEduData = await RemoteInstitution.getInstitutionData({ email })
    if (getEduData.success) {
      const eduData = getEduData.data

      return this.setState({
        eduName: eduData.edu_name,
        postalCode: eduData.postal_code,
        eduEmail: eduData.email,
        phoneNumber: eduData.phone_number,
        ...eduData
      })
    }
  }

  onCancel () {
    this.props.history.goBack()
  }

  onChangeHandler (e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      [e.target.name]: value
    })
  }

  async onSubmitHandler (e) {
    e.preventDefault()
    const { eduName, uic, contractor, address, city, state, postalCode, country, eduEmail, phoneNumber, id } = this.state
    const newData = {
      edu_name: eduName,
      postal_code: postalCode,
      email: eduEmail,
      phone_number: phoneNumber,
      uic,
      contractor,
      address,
      city,
      state,
      country,
      id
    }
    const editInstitution = await RemoteInstitution.editInstitution(newData)

    if (editInstitution.success) {
      const { eduName } = editInstitution.data
      window.localStorage.setItem('gdirInstitutionName', eduName)
      return this.props.history.push('/edu/profile')
    }
  }

  render () {
    const {
      error,
      eduName,
      uic,
      contractor,
      address,
      city,
      state,
      postalCode,
      country,
      eduEmail,
      phoneNumber
    } = this.state
    return (
      <div className='registration-page'>
        <div className='registration-page-cover'>
          <img className='registration-page-img' src={registrationCoverImage} alt='Registration img' />
          <span className='title'>edit educational institution profile</span>
          <div className='cover-bottom-line' />
        </div>
        <form className='registration-form' onSubmit={this.onSubmitHandler}>
          <div>{error}</div>
          <div className='form-fields'>
            <div>
              <label>Institution name*</label>
              <Input
                name='eduName'
                placeholder='Institution Name'
                value={eduName}
                onChange={this.onChangeHandler} />
            </div>
            <div>
              <label>Unique identification code</label>
              <Input
                name='uic'
                placeholder='Unique Identification Code'
                value={uic}
                onChange={this.onChangeHandler} />
            </div>
            <div>
              <label>Contractor</label>
              <Input
                name='contractor'
                placeholder='Contractor'
                value={contractor}
                onChange={this.onChangeHandler} />
            </div>
            <div>
              <label>Address</label>
              <Input
                name='address'
                placeholder='Address'
                value={address}
                onChange={this.onChangeHandler} />
            </div>
            <div>
              <label>City</label>
              <Input
                name='city'
                placeholder='City'
                value={city}
                onChange={this.onChangeHandler} />
            </div>
            <div>
              <label>State</label>
              <Input
                name='state'
                placeholder='State'
                value={state}
                onChange={this.onChangeHandler} />
            </div>
            <div>
              <label>Postal code</label>
              <Input
                name='postalCode'
                placeholder='Postal Code'
                value={postalCode}
                onChange={this.onChangeHandler} />
            </div>
            <div>
              <label>Country</label>
              <Input
                name='country'
                placeholder='Country'
                value={country}
                onChange={this.onChangeHandler} />
            </div>
            <div>
              <label>E-mail*</label>
              <Input
                name='eduEmail'
                placeholder='E-mail'
                value={eduEmail}
                onChange={this.onChangeHandler}
                required />
            </div>
            <div>
              <label>Phone number*</label>
              <Input
                name='phoneNumber'
                placeholder='Phone number'
                value={phoneNumber}
                onChange={this.onChangeHandler}
                required />
            </div>
          </div>
          <button className='btn btn-register'>Edit</button>
        </form>
      </div>
    )
  }
}
