import React, { Component } from 'react'

export default class TextArea extends Component {
  render() {
    const { name, rows = 10, cols = 21, placeholder, value, onChange, required = false } = this.props

    return (
      <textarea
        name={name}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        required={required}
      >
      </textarea>
    )
  }
}
