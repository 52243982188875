import React from 'react'
import { Switch, Route } from 'react-router-dom'

import HomePage from '../../Home/HomePage'
import InstitutionsPage from '../../Institutions/InstitutionsPage'
import UsersPage from '../../Users/UsersPage'
// import CreateDiplomaPage from '../../Diploma/CreateDiplomaPage'
import ProfilePage from '../../Profile/ProfilePage'
import UserRegisterPage from '../../Auth/users/RegisterPage'
import UserLoginPage from '../../Auth/users/LoginPage'
import EduRegisterPage from '../../Auth/institutions/RegisterPage'
import EduLoginPage from '../../Auth/institutions/LoginPage'
// import LogoutPage from '../../common/LogoutPage'
// import EduDetailsPage from '../../Details/EduDetailsPage'
import DetailsPage from '../../Details/DetailsPage'
import AboutPage from '../../About/AboutPage'
import TermsAndConditions from '../../TermsAndConditions/TermsAndConditions'
import PrivacyPolicy from '../../TermsAndConditions/PrivacyPolicy'
import FaqPage from '../../FAQ/FaqPage'
import PrinterPage from '../../Printer/PrinterPage'
import ContactPage from '../../Contact/ContactPage'
import CreateDiplomaEdu from '../../Diploma/CreateDiplomaEdu'
import CreateDiplomaUser from '../../Diploma/CreateDiplomaUser'
import SearchPage from '../../Search/SearchPage'
import AllDiplomas from '../../Diploma/AllDiplomas'
import ShowAll from '../../Diploma/ShowAll'
import UserProfilePage from '../../Users/ProfilePage'
import EduProfilePage from '../../Institutions/ProfilePage'
import ListEduVerifiedDiplomas from '../../Diploma/ListEduVerifiedDiplomas'
import NotFound from '../NotFound'
// edit pages
import EditEduProfile from '../../Auth/institutions/EditEduProfile'
import EditAgentProfile from '../../Auth/institutions/EditAgentProfile'
import EditUserProfile from '../../Auth/users/EditUserProfile'

import Print from '../../Printer/Print'

const Routes = () => (
  <Switch>
    <Route exact path='/' component={HomePage} />
    <Route path='/institutions' component={InstitutionsPage} />
    <Route path='/users' component={UsersPage} />
    <Route path='/profile' component={ProfilePage} />
    <Route path='/auth/user/register' component={UserRegisterPage} />
    <Route path='/auth/user/login' component={UserLoginPage} />
    <Route path='/auth/edu/register' component={EduRegisterPage} />
    <Route path='/auth/edu/login' component={EduLoginPage} />
    {/* <Route path='/logout' component={LogoutPage} /> */}
    <Route path='/diploma/details/:id' component={DetailsPage} />
    <Route path='/edu/details/:id' component={DetailsPage} />
    {/* <Route path='/edu/details/:id' component={EduDetailsPage} /> */}
    <Route path='/about' component={AboutPage} />
    <Route path='/faq' component={FaqPage} />
    <Route path='/printer' component={PrinterPage} />
    <Route path='/contact' component={ContactPage} />
    <Route path='/terms' component={TermsAndConditions} />
    <Route path='/privacypolicy' component={PrivacyPolicy} />

    <Route path='/edu/newdiploma' component={CreateDiplomaEdu} />
    <Route path='/user/newdiploma' component={CreateDiplomaUser} />
    <Route path='/user/profile' component={UserProfilePage} />
    <Route path='/edu/profile' component={EduProfilePage} />
    <Route path='/edu/verified' component={ListEduVerifiedDiplomas} />

    <Route path='/edu/edit' component={EditEduProfile} />
    <Route path='/edu/editagent' component={EditAgentProfile} />
    <Route path='/user/edit' component={EditUserProfile} />

    <Route path='/search' component={SearchPage} />
    <Route path='/all' component={AllDiplomas} />
    <Route path='/diploma/all' component={ShowAll} />
    <Route path='/print' component={Print} />
    <Route component={NotFound} />
  </Switch>
)

export default Routes
