import Remote from './remote'

export default class RemotePrinter {
  static getRfid () {
    return Remote.printerGet('/readtag')
  }

  static printQr (base64Image) {
    return Remote.printerPost('/printpng', { img: base64Image })
  }
}
