import React, { Component } from 'react'

export default class Select extends Component {
  render() {
    // const { name, label, value, onChange, options } = this.props
    const { name, value, onChange, options } = this.props

    const myOptions = options.map(opt =>
      <option key={opt} value={opt}>{opt}</option>
    )

    return (
      <select
        className='custom-select-sm'
        id='select-box'
        name={name}
        value={value}
        onChange={onChange}
      >
        {myOptions}
      </select>
    )
  }
}
