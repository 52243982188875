import React, { Component } from 'react'
import DiplomaPreview from '../Diploma/DiplomaPreview'
import { detailsPage } from '../common/helpers/pageText.js'
import coverImage from '../../images/register/gdir_cover_profile.png'
import UserProfileComponent from '../Users/ProfileComponent'
import EduProfileComponent from '../Institutions/ProfileComponent'
import RemoteDiploma from '../../api/remote_diploma'

export default class DetailsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      user: {
        email: '',
        name: '',
        role: ''
      },
      diploma: {}
    }
  }

  componentDidMount () {
    this.getData()
  }

  async getData () {
    const { id } = this.props.match.params
    const user = window.localStorage.getItem('gdirUser')
    const username = window.localStorage.getItem('gdirUsername')
    const userRole = window.localStorage.getItem('gdirUserRole')
    const institution = window.localStorage.getItem('gdirInstitutionName')

    const diploma = await RemoteDiploma.getDetails(id)

    this.setState({
      user: {
        email: user,
        name: username,
        role: userRole,
        institution
      },
      diploma
    })
  }

  render () {
    const { user, diploma } = this.state
    let data = 'Nothing to show!'

    if (diploma.success) {
      data = diploma.data
    }

    return (
      <div className='details-page'>
        <div className='details-page-cover'>
          <img
            className='details-page-img'
            src={coverImage}
            alt='Details img'
          />
          <span className='title'>{detailsPage.title}</span>
          <div className='cover-bottom-line' />
        </div>
        <div className='content-wrapper'>
          {user.role === 'institution' ? (
            <EduProfileComponent user={user.name} edu={user.institution} />
          ) : (
            <UserProfileComponent user={user.name} />
          )}
          <div className='secondary-wrapper'>
            {diploma.data ? (
              <DiplomaPreview
                diploma={data}
                login={user.role === 'institution' && 'institution'}
              />
            ) : (
              <p>loading...</p>
            )}
          </div>
        </div>
      </div>
    )
  }
}
