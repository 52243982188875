import Remote from './remote'

const baseUrl = '/auth'

export default class RemoteAuth {
  static registerUser (user) {
    return Remote.post(`${baseUrl}/user/register`, user)
  }

  static registerInstitution (institution) {
    return Remote.post(`${baseUrl}/edu/register`, institution)
  }

  static login (user) {
    return Remote.post(`${baseUrl}/login`, user)
  }
}
