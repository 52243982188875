import React, { Component } from 'react'
import ContactForm from './ContactForm'
import { contact } from '../common/helpers/pageText'
import coverImage from '../../images/register/gdir_cover_profile.png'

export default class ContactPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      subject: '',
      message: '',
      error: ''
    }

    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
  }

  onChangeHandler(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onSubmitHandler(e) {
    e.preventDefault()
    const user = window.localStorage.getItem('gdirUserRole')
    const role = user === 'institution' ? 'edu' : user
    const routePath = `/${role}/profile`

    if (user) {
      return this.props.history.push(routePath)
    }

    this.props.history.push('/')
  }

  render() {
    return (
      <div className='contact-page'>
        <div className='contact-page-cover'>
          <img className='contact-page-img' src={coverImage} alt='Contact img' />
          <span className='title'>{contact.title}</span>
          <div className='cover-bottom-line'>
          </div>
        </div>
        <ContactForm
          {...this.state}
          onChange={this.onChangeHandler}
          onSubmit={this.onSubmitHandler} />
      </div>
    )
  }
}
