export let questions = [
  {
    id: 1,
    question: 'Какво е GDIR?',
    answer: 'GDIR е система за верифициране на истинността на дипломи и образователни сертификати, целяща да намали фалшифицирането и изкупуването им. Чрез GDIR, всеки един потребител ще може да провери истинността на дадена диплома и образователен сертификат, както и валидността на институцията, издала документа.'
  },
  {
    id: 2,
    question: 'За кого е GDIR?',
    answer: 'GDIR е за всеки, който иска да верифицира истинността на своята диплома или образователен сертификат, да провери валидността на самата диплома или образователен сертификат, както и валидността на институцията, издала документа. '
  }
]
