import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import defaultUserImg from '../../images/user.png'

export default class ProfileComponent extends Component {
  render () {
    const { userImg = defaultUserImg, user, edu } = this.props
    return (
      <div className='profile-section'>
        <div className='user-profile'>
          <img src={userImg} alt='User img' />
          <p>{user}</p>
          <p>{edu}</p>
          <div className='profile-links'>
            <NavLink activeClassName='active' to='/edu/editagent'>Edit personal profile</NavLink>
            <NavLink activeClassName='active' to='/edu/edit'>Edit institution profile</NavLink>
          </div>
        </div>
      </div>
    )
  }
}
