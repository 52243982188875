import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { redirectionLink } from './helpers/helpers'
import userLogo from '../../images/logo/gdir-user.png'

class LoginMenu extends Component {
  constructor() {
    super()

    this.state = {
      showMenu: false
    }

    this.showMenu = this.showMenu.bind(this)
    this.eduLogin = this.eduLogin.bind(this)
    this.userLogin = this.userLogin.bind(this)
    this.setRedirectionPath = this.setRedirectionPath.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.getElementById('btn-login-toggle').click()
  }

  setRedirectionPath() {
    if (String(window.location.pathname).includes('search')) {
      redirectionLink.path = window.location.pathname + window.location.search
    }
  }

  eduLogin() {
    this.setRedirectionPath()
    this.setState({
      showMenu: false
    })
    this.props.history.push('/auth/edu/login')
  }

  userLogin() {
    this.setRedirectionPath()
    this.setState({
      showMenu: false
    })
    this.props.history.push('/auth/user/login')
  }

  showMenu(e) {
    e.preventDefault()

    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  render() {
    return (
      <div className='navbar-login'>
        <img id='user-login-logo' className='navbar-items' src={userLogo} alt='User login logo' />
        <div className='btn-toggle navbar-items'>Login</div>
        {/* <Link to='' className='btn-toggle'>Login</Link> */}
        <ul id='dropdown' className='navbar-login-options'>
          <li>
            <Link className='select-option' to='#' onClick={this.eduLogin}>
              Institution Login
          </Link>
          </li>
          <li>
            <Link className='select-option' to='#' onClick={this.userLogin}>
              User Login
          </Link>
          </li>
        </ul>
      </div>
      /* <Link to='#' id='btn-login-toggle' className='btn-toggle' onClick={this.showMenu}>Login</Link>
      {
        this.state.showMenu
          ? (
            <div className='navbar-login-options'>
              <Link className='select-option' to='#' onClick={this.eduLogin}>
                Institution Login
              </Link>
              <Link className='select-option' to='#' onClick={this.userLogin}>
                User Login
              </Link>
            </div>
          )
          : (
            null
          )
      } */
    )
  }
}

export default withRouter(LoginMenu)
