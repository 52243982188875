export const about = {
  title: 'За GDIR',
  firstParagraph:
    'Глобалният регистър на дипломи и образователни сертификати, или накратко - GDIR, представлява глобална информационна система, която регистрира и пази информация за всяка една диплома или образователен сертификат, издадени от независима, частна или държавна образователна институция.',
  secondParagraph:
    'След направена регистрация, всяка една диплома или образователен сертификат ще получат уникален виртуален идентификационен номер. Получения идентификационен номер ще верифицира истинността на всяка една диплома или образователен сертификат.',
  gdir: 'BULSUKO000001'
}

export const faq = {
  title: 'Frequently asked questions /FAQ/'
}

export const printer = {
  title: 'Hologram Sticker Printer',
  printerDescription:
    'NFC е безжична технология, която позволява високоскоростно прехвърляне на данни от едно устройство на друго на близки разстояния. Всъщност, това е една по-модерна версия на RFID (Radio-frequency identification) стандартите, които използват радиовълни за идентификация. Но NFC  има свой, уникален набор от характеристики, които ще определят как да се използва в реални приложения. Джаджите, които имат вграден NFC чип са в състояние да разчитат и предават данни на друго такова устройство на разстояние 4- 10 см. Принтера чете данните от NFC чип, след което с помощта на уникалния номер от чипа, може да се свърже с дадена диплома',
  stickerDescription:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse in orci sit amet ante vestibulum euismod pretium eu quam. Aliquam vitae ultricies mauris. Phasellus a tempor ex. Suspendisse potenti. In pharetra, metus ut maximus vestibulum, enim est sodales enim, consequat accumsan tellus enim sed est. Sed dui augue, malesuada non tempor non, ullamcorper sed nibh. Mauris sit amet odio at dolor fringilla venenatis. Etiam eros odio, condimentum vel justo ut, fringilla consectetur augue.',
  printerImageUrl:
    'https://p.globalsources.com/IMAGES/PDT/B0865387996/Thermal-printers.jpg',
  stickerImageUrl: 'http://www.hlhologram.com/images/Englsih_index.jpg'
}

export const contact = {
  title: 'Contact Us'
}

export const terms = {
  title: 'Terms and Conditions of use',
  firstParagraph: {
    t1: {
      title: 'I. ПРЕДМЕТ',
      p1:
        'Чл. 1. Настоящите общи условия са предназначени за регулиране на отношенията между "Диджимарк Студио ООД" , ЕИК xxxxxxxx , със седалище и адрес на управление: кв. Изток, ул. Тарас Шевченко 5А, София 1113 , наричано по-долу за краткост ДОСТАВЧИК, и клиентите, наричани по-долу ПОЛЗВАТЕЛИ, на глобалния регистър за дипломи и сертификати- GDIR.'
    },
    t2: {
      title: 'ІІ. ДАННИ ЗА ДОСТАВЧИКА\n',
      p1:
        'Чл. 2. Информация съгласно Закона за електронната търговия и Закона за защита на потребителите:\n Наименование на Доставчика:"Диджимарк Студио ООД"\n Седалище и адрес на управление: кв. Изток, ул. Тарас Шевченко 5А, София 1113\n Адрес за упражняване на дейността и адрес за отправяне на жалби от потребители: кв. Изток, ул. Тарас Шевченко 5А, София 1113\nДанни за кореспонденция: info@digimark.bg, телефон xxxxxxxxxx\nВписване в публични регистри: ЕИК xxxxxxx\nНадзорни органи:',
      p2: ' \n',
      p3:
        '(1) Комисия за защита на личните данни\nАдрес: гр. София, ул. „Проф. Цветан Лазаров” № 2\nтел.: (02) 940 20 46\nфакс: (02) 940 36 40\nEmail: kzld@government.bg, kzld@cpdp.bg\nУеб сайт: www.cpdp.bg\n',
      p4: ' \n',
      p5:
        '(2) Комисия за защита на потребителите\nАдрес: 1000 гр. София, пл."Славейков" №4А, ет.3, 4 и 6\nтел.: 02 / 980 25 24\nфакс: 02 / 988 42 18\nгореща линия: 0700 111 22\nУеб сайт: www.kzp.bg\n'
    }
  },
  secondParagraph: {
    t1: {
      title: 'ЗАЩИТА НА ЛИЧНИТЕ ДАННИ',
      p1:
        '(1) Събирането, съхранението и обработването на лични данни се осъществява съобразно Политиката на за поверителност на личните данни, която можете да достъпите тук Политиката за поверителност.',

      p2:
        '(2) Въведените от Ползвателите лични данни са обект на защита по Закона за защита на личните данни и Общия регламент 2016/679 като Доставчикът ги обработва за целите и в сроковете, предвидени в Политиката за поверителност на личните данни.',

      p3:
        '(3) При съгласие на Ползвателя с Политиката за поверителност на личните данни, Ползвателят изрично потвърждава, че е съгласен Доставчикът да съхранява информация или получава достъп до информацията, съхранена в крайното устройство на Ползвателя за изчерпателно предвидените в нея цели и срокове. Ползвателят се съгласява, че Доставчикът може  съхранява информация или получава достъп до информацията, съхранена в крайното устройство на Ползвателя и на други основания, посочени в Политиката за поверителност на личните данни.',

      p4:
        '(4) Ползвателят или Потребителят се съгласява, че Доставчикът на платформата digimark.bg има право да изпраща по всяко време електронни съобщения към Ползвателя или Потребителя, включително и бюлетин или предложения за покупка на стоки, докато е налице регистрация на Ползвателя или Потребителя в електронния магазин на Доставчика в платформата digimark.bg',

      p5:
        '(5) Ползвателят или Потребителят се съгласява, че Доставчикът на платформата digimark.bg има право да събира, съхранява и обработва данни да поведението на Ползвателя или Потребителя при използването на електронния магазин на Доставчика в платформата digimark.bg  Ползвателят има право да възрази на съхраняването или достъпа до информацията по алинея 3 по предвидените в Политиката за поверителност на личните данни начини.',

      p6:
        '- Във всеки момент, Доставчикът в платформата digimark.bg има право да изисква от Ползвателя да се легитимира и да удостовери достоверността на всяко едно от обявените по време на регистрацията обстоятелства и лични данни.',

      p7:
        '- В случай, че по някаква причина Ползвателят е забравил или изгубил своите име и парола, Доставчикът на платформата digitalpro.bg има право да приложи обявената "Процедура за изгубени или забравени имена и пароли", достъпна на адрес: digimark.bg'
    }
  }
}

export const privacy = {
  title: 'Privacy Policy',
  firstParagraph: {
    t1: {
      title:
        'Декларация за съгласие с Политиката за поверителност на Диджимарк Студио ООД',
      p1:
        'Защитата на личните данни и опазването на личната и финансовата информация на нашите клиенти ни е особено важно. Затова обработваме Вашата информация изключително въз основа на приложимото законодателство, конкретно Общия регламент 2016/670 (по-известен като GDPR ), Закона за защита на лични данни ( ЗЗЛД ) и Закона за електронната търговия ( ЗЕТ ). Моля, разгледайте тази декларация, в която Ви информираме за най-важните аспекти от обработването на Вашите данни от страна на (последващо Ние или Дружеството). В тази декларация ви информираме за най-важните аспекти от обработването на Вашите данни на нашия уебсайт. С нея също така Ви информираме за сроковете и правните основания да събираме, обработваме, съхраняваме, използваме, унищожаваме и защитаваме личната Ви информация, доколкото с нея Вие не ни давате изричното си съгласие да вършим това.',
      p2:
        'Какво се случва с данните Ви, след като се свържете се нас? Когато ни пишете чрез формуляра на нашия уебсайт или ни пращате имейл, ние съхраняваме данните Ви за периода, необходим за обработване на искането Ви, както и за да отговорим на Ваши последващи въпроси. Няма да споделяме тази информация с трети лица без Вашето изрично съгласие!',

      p3:
        'Съхраняване на Вашите данни: Обръщаме внимание, че с цел облекчаване на процеса по поръчка и покупка, както и последващото изпълнение на договора с Вас, съхраняваме Вашия IP адрес, както и Вашите имена, адрес, имейл адрес и данни за използваното от Вас платежно средство, например номер на кредитната Ви карта.',

      p5:
        'Предоставените от Вас данни са ни нужни, за да изпълним своите преддоговорни или договорни задължения спрямо Вас. Не предоставяме тези данни на трети лица, с изключение на:',

      p6:
        '– обработващия плащането доставчик на платежни услуги (банка, PayPal, ePay),',

      p7: '– куриерската фирма, с която Ви доставяме покупката',

      p8: '– нашия счетоводител',

      p9:
        'При незавършена поръчка оставяме въведените по нея данни за 30 дни и си запазваме правото да Ви информираме периодично за нейния статус, съответно за Вашите опции вследствие на това. При завършена поръчка и изпълнен от наша страна договор на основание чл. 6, ал. 1, букви (а) и (б) от GDPR съхраняваме всички данни по правоотношението до изтичане на предвидения в чл. 12 от Закона за счетоводството ( ЗСч ) 10-годишен срок.'
    }
  }
}

export const diplomaPage = {
  title: 'Diploma Create'
}

export const userHomePage = {
  title: 'User home page'
}

export const eduHomePage = {
  title: 'Educational institution home page'
}

export const detailsPage = {
  title: 'Diploma details page'
}

export const verifiedPage = {
  title: 'Verified diploma page'
}

export const showAll = {
  verified: {
    title: 'Show all verified diplomas'
  },
  pending: {
    title: 'Show all pending diplomas'
  },
  rejected: {
    title: 'Show all rejected diplomas'
  }
}

export const printPage = {
  title: 'Print GDIR QR Code'
}
