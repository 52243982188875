import React, { Component } from 'react'
import Input from '../common/forms/Input'
import TextArea from '../common/forms/TextArea'

export default class ContactForm extends Component {
  render() {
    const { email, subject, message, error, onChange, onSubmit } = this.props
    return (
      <div className='page-content'>
        <form className='contact-form' onSubmit={onSubmit}>
          <div>{error}</div>
          {/* <div> */}
          {/* <label>E-mail</label> */}
          <Input
            name='email'
            type='email'
            placeholder='Email'
            value={email}
            onChange={onChange}
            required />
          {/* </div> */}
          {/* <div> */}
          {/* <label>Subject</label> */}
          <Input
            name='subject'
            placeholder='Subject'
            value={subject}
            onChange={onChange}
            required />
          {/* </div> */}
          {/* <div> */}
          {/* <label>Message</label> */}
          <TextArea
            name='message'
            placeholder='Message'
            value={message}
            onChange={onChange}
            required
          />
          {/* </div> */}
          <button className='btn btn-submit'>Submit</button>
        </form>
      </div>
    )
  }
}
