import React, { Component } from 'react'
import DiplomaCard from './DiplomaCard'
import coverImage from '../../images/register/gdir_cover_profile.png'
import ProfileComponent from '../Users/ProfileComponent'
import RemoteUser from '../../api/remote_user'
import { showAll } from '../common/helpers/pageText'
import queryString from 'query-string'

export default class ShowAll extends Component {
  constructor (props) {
    super(props)
    this.state = {
      status: '',
      searchValue: '',
      searchActive: false,
      searchPage: 1,
      init: [],
      items: [],
      diplomas: [],
      showTitle: '',
      page: 1,
      limit: 2
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.goToPrevPage = this.goToPrevPage.bind(this)
    this.goToNextPage = this.goToNextPage.bind(this)
  }

  goToPrevPage () {
    const { init, diplomas, searchActive, searchPage, status } = this.state
    let page = this.state.page

    if (searchActive) {
      page = searchPage
    }

    if (page === 1) {
      return
    }
    page -= 1
    // const data = this.updateItems(init, page)

    if (!searchActive) {
      this.setState({
        page,
        items: this.updateItems(init, page)
      })
      this.props.history.push(`/diploma/all?status=${status}&page=${page}`)
    } else {
      this.setState({
        searchPage: page,
        items: this.updateItems(diplomas, page)
      })
    }
  }

  goToNextPage () {
    const { init, diplomas, searchActive, searchPage, status } = this.state
    let page = this.state.page

    if (searchActive) {
      page = searchPage
    }

    if (this.state.items.length === 0) {
      return
    }
    page += 1
    // const data = this.updateItems(init, page)

    if (!searchActive) {
      this.setState({
        page,
        items: this.updateItems(init, page)
      })
      this.props.history.push(`/diploma/all?status=${status}&page=${page}`)
    } else {
      this.setState({
        searchPage: page,
        items: this.updateItems(diplomas, page)
      })
    }
  }

  async componentDidMount () {
    const data = await this.getData()

    this.setState(data)
  }

  updateItems (data, page) {
    const { limit } = this.state
    let filteredData = data.slice(
      (page - 1) * limit,
      (page - 1) * limit + limit
    )

    return filteredData
  }

  async getData () {
    const req = queryString.parse(this.props.location.search)
    const status = req.status
    const { page, limit } = this.state
    const email = window.localStorage.getItem('gdirUser')
    const username = window.localStorage.getItem('gdirUsername')

    let data = 'Nothing to show!'
    let showTitle = 'Show all diplomas'
    let diploma

    if (status === 'verified') {
      showTitle = showAll.verified.title
      diploma = await RemoteUser.getVerifiedDiplomas()
    } else if (status === 'pending') {
      showTitle = showAll.pending.title
      diploma = await RemoteUser.getPendingDiplomas()
    } else if (status === 'rejected') {
      showTitle = showAll.rejected.title
      diploma = await RemoteUser.getRejectedDiplomas()
    }

    // const diplomaPromise = RemoteUser.getDiplomas({ status: status })
    // const diploma = await diplomaPromise

    if (diploma.success) {
      data = diploma.data
    }

    let filteredData = data.slice(
      (page - 1) * limit,
      (page - 1) * limit + limit
    )

    const result = {
      user: {
        username,
        email
      },
      init: data,
      items: filteredData,
      showTitle,
      status
    }

    return result
  }

  handleSearch (e) {
    let currentList = this.state.init
    let newList = []
    let diplomas = []
    let isActive = this.state.searchActive
    const searchValue = e.target.value

    const reqQuery = queryString.parse(this.props.location.search)
    const page = reqQuery.page || 1

    if (searchValue !== '') {
      newList = currentList.filter(item => {
        const inst = item.institution.toLowerCase()
        const fos = item.field_of_study.toLowerCase()
        const deg = item.degree.toLowerCase()
        const own = item.owner.toLowerCase()
        const reg = item.registration_number.toLowerCase()
        const filter = searchValue.toLowerCase()
        let gdn = ''
        if (item.gdir) {
          gdn = item.gdir.toLowerCase()
        }

        return (
          inst.includes(filter) ||
          fos.includes(filter) ||
          deg.includes(filter) ||
          own.includes(filter) ||
          gdn.includes(filter) ||
          reg.includes(filter)
        )
      })
      isActive = true
      diplomas = newList
    } else {
      newList = currentList
      isActive = false
    }

    this.setState({
      items: this.updateItems(newList, page),
      searchActive: isActive,
      searchPage: 1,
      diplomas
    })
  }
  render () {
    const username = window.localStorage.getItem('gdirUsername')
    let { items, showTitle, limit } = this.state
    let page = this.state.page

    if (this.state.searchActive) {
      page = this.state.searchPage
    }

    return (
      <div className='user-home-page'>
        <div className='user-home-page-cover'>
          <img
            className='user-home-page-img'
            src={coverImage}
            alt='User home page img'
          />
          <span className='title'>{showTitle}</span>
          <div className='cover-bottom-line' />
        </div>
        <div className='content-wrapper'>
          <ProfileComponent user={username} />
          <div className='secondary-wrapper'>
            <div className='show-all'>
              <div className='search'>
                <input
                  className='search-diploma-field'
                  type='text'
                  placeholder='Search...'
                  onChange={this.handleSearch}
                />
              </div>
              {items.map(d => {
                return <DiplomaCard key={d.diploma_id} {...d} />
              })}
              <div id='paginator'>
                {page > 1 && (
                  <button
                    className='btn btn-prev'
                    onClick={this.goToPrevPage}
                  />
                )}
                <span>{page}</span>
                {items.length >= limit && (
                  <button
                    className='btn btn-next'
                    onClick={this.goToNextPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
