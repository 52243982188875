import React, { Component } from 'react'
import { printer } from '../common/helpers/pageText'
import coverImage from '../../images/register/gdir_cover_profile.png'

export default class PrinterPage extends Component {
  render () {
    return (
      <div className='printer-page'>
        <div className='printer-page-cover'>
          <img
            className='printer-page-img'
            src={coverImage}
            alt='Printer img'
          />
          <span className='title'>{printer.title}</span>
          <div className='cover-bottom-line' />
        </div>
        <div className='page-content'>
          <div>
            <span>Printer</span>
            <img src={printer.printerImageUrl} alt='printer' />
            <p>{printer.printerDescription}</p>
          </div>
          {/* <div>
            <span>Stickers</span>
            <img src={printer.stickerImageUrl} alt='stickers' />
            <p>{printer.stickerDescription}</p>
          </div> */}
        </div>
      </div>
    )
  }
}
