import React, { Component } from 'react'
import { about } from '../common/helpers/pageText'
import coverImage from '../../images/register/gdir_cover_profile.png'

export default class AboutPage extends Component {
  render() {
    return (
      <div className='about-page'>
        <div className='about-page-cover'>
          <img className='about-page-img' src={coverImage} alt='About img' />
          <span className='title'>{about.title}</span>
          <div className='cover-bottom-line'>
          </div>
        </div>
        <div className='page-content'>
          <p>{about.firstParagraph}</p>
          <input className='number-example' type='text' value={about.gdir} disabled />
          <p>{about.secondParagraph}</p>
        </div>
      </div>
    )
  }
}
