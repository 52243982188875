import React, { Component } from 'react'
import DiplomaCard from './DiplomaCard'
import { diploma } from '../common/helpers/diplomaData'

export default class ListDiplomas extends Component {
  render () {
    // const { diploma } = this.props
    return (
      <div>
        {diploma.map(d => {
          return (
            <DiplomaCard
              key={d.id}
              {...d}
            />
          )
        })}

      </div>

    )
  }
}
